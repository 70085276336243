import { Grid, Typography } from '@mui/material';
import { UserManagementWrapper } from 'components/user-management/UserManagementWrapper';
import { ManagementFormWrapper } from 'components/user-management/UserManagementWrapper.styled';
import { t } from 'i18next';
import { ROUTES } from 'parameters';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logoImg from '../../../assets/logo.png';

type Props = {};

export function Forbidden(props: Props) {
    const navigate = useNavigate();

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate('app/' + ROUTES.home);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [navigate]);

    const title = t('forbidden.title');

    return (
        <UserManagementWrapper>
            <ManagementFormWrapper>
                <Grid container direction='column' justifyContent='center' alignItems='center' gap={4} p={1}>
                    <Grid container item direction='column' justifyContent='flex-start' alignItems='center'>
                        <img src={logoImg} alt='logo' />
                        <Typography variant='h6' component='h1'>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </ManagementFormWrapper>
        </UserManagementWrapper>
    );
}
