import type { BoxProps } from '@mui/material/Box';
import type { CardProps } from '@mui/material/Card';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { fToNow } from 'utils/formatTime';

type PostItemProps = {
    id: string;
    title: string;
    coverUrl: string;
    totalViews: number;
    description: string;
    totalShares: number;
    totalComments: number;
    totalFavorites: number;
    postedAt: string | number | null;
    author: {
        name: string;
        avatarUrl: string;
    };
};

// ----------------------------------------------------------------------

type Props = CardProps & {
    title?: string;
    subheader?: string;
    list: PostItemProps[];
};

export function AnalyticsNews({ title, subheader, list, ...other }: Props) {
    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} sx={{ mb: 1 }} />

            <Box sx={{ minWidth: 640 }}>
                {list.map(post => (
                    <PostItem key={post.id} item={post} />
                ))}
            </Box>

            {/* <Box sx={{ p: 2, textAlign: 'right' }}>
                <Button
                    size='small'
                    color='inherit'
                    endIcon={<KeyboardArrowRightIcon />}>
                    View all
                </Button>
            </Box> */}
        </Card>
    );
}

// ----------------------------------------------------------------------

function PostItem({ sx, item, ...other }: BoxProps & { item: Props['list'][number] }) {
    return (
        <Box
            sx={{
                py: 2,
                px: 3,
                gap: 2,
                display: 'flex',
                alignItems: 'center',
                borderBottom: theme => `dashed 1px ${theme.palette.divider}`,
                ...sx
            }}
            {...other}>
            <Avatar
                variant='rounded'
                alt={item.title}
                src={item.coverUrl}
                sx={{ width: 48, height: 48, flexShrink: 0 }}
            />

            <ListItemText
                primary={item.title}
                secondary={item.description}
                primaryTypographyProps={{ noWrap: true, typography: 'subtitle2' }}
                secondaryTypographyProps={{ mt: 0.5, noWrap: true, component: 'span' }}
            />

            <Box sx={{ flexShrink: 0, color: 'text.disabled', typography: 'caption' }}>{fToNow(item.postedAt)}</Box>
        </Box>
    );
}
