import React, { useContext, useMemo } from 'react';
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { GetAdminVehicles } from 'services';
import { useQuery } from 'react-query';
import { UserContext } from 'context';
import { VehicleAdminEditDialog } from './VehicleAdminEditDialog';
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_HR } from 'material-react-table/locales/hr';
import i18n from 'services/global/i18n.service';
import EditIcon from '@mui/icons-material/Edit';

export const AllAdminVehicleGrid = () => {
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState<number>(Infinity);
    const { t } = useTranslation();

    const { selectedUserId } = useContext(UserContext);

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            { accessorKey: 'id', header: 'ID', size: 50 },
            {
                accessorKey: 'user',
                header: t('marketGrid.User'),
                size: 130,
                Cell: ({ row, cell }: any) => {
                    let selectedVehicle = row.original.user;
                    return selectedVehicle
                        ? `${selectedVehicle?.name || t('marketGrid.NoName')} - ${selectedVehicle?.id}`
                        : t('marketGrid.NoUser');
                }
            },
            { accessorKey: 'vehicle_category', header: t('userVehicles.VehicleCategoryLabel'), size: 130 },
            { accessorKey: 'vehicle_brand', header: t('userVehicles.VehicleBrandLabel'), size: 130 },
            { accessorKey: 'vehicle_model', header: t('userVehicles.VehicleModelLabel'), size: 130 },
            { accessorKey: 'vin', header: t('userVehicles.VinLabel'), size: 130 }
        ],
        []
    );

    const handleClose = () => {
        setDetailsDialog(false);
    };

    const { data, isError, isRefetching, isLoading } = useQuery(
        ['admin_vehicles', selectedUserId],
        () => GetAdminVehicles(selectedUserId),
        {
            staleTime: 10000
        }
    );

    const table = useMaterialReactTable({
        columns,
        data: data || ([] as any),
        initialState: { showColumnFilters: true },
        enableEditing: true,

        getRowId: row => row.id,
        localization: i18n.language === 'hr' ? MRT_Localization_HR : (MRT_Localization_EN as any),
        state: {
            isLoading: isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching
        },
        renderRowActions: ({ row, table }) => {
            const onClick = () => {
                let selectedID: number = row.original.id;
                setSelectedRow(selectedID);
                setDetailsDialog(true);
            };

            return (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip title='Edit'>
                        <IconButton onClick={onClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        }
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <VehicleAdminEditDialog selected={selectedRowID} open={openDetailsDialog} onClose={handleClose} />
        </>
    );
};
