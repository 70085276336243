import React, { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components';
import VehicleForm from './VehicleForm';
import { PostNewAdminVehicle, PostNewVehicle } from 'services';
import { toast } from 'react-toastify';
import { IVehicleInput } from 'models';
import { UserContext } from 'context';
import { queryClient } from 'index';

export interface NotificationDialogProps {
    open: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    isAdmin?: boolean;
}

export const VehicleAddDialog = (props: NotificationDialogProps) => {
    const vehicleFormRef = useRef<any>(null);
    const { selectedUserId } = useContext(UserContext);

    const { onClose, open, onOpen, isAdmin } = props;

    const { t } = useTranslation();

    const handleClose = () => {
        onClose && onClose();
    };

    const submitfunc = isAdmin ? PostNewAdminVehicle : PostNewVehicle;

    const handleAccept = () => {
        vehicleFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: IVehicleInput) => {
        submitfunc({ data, selectedUserId })
            .then(res => {
                toast.success(t('Save Successful'));
            })
            .catch(err => {
                console.error('err', err);
                toast.error(`${t('Save Failed')}: ${err.message}`);
            })
            .finally(() => {
                handleClose();
                queryClient.invalidateQueries(['user_vehicles', selectedUserId]);
            });
    };

    // const vehicleQuery = useQuery(['vehicle', selected], () => GetVehicle({ id: selected }), {
    //     staleTime: 86400,
    //     enabled: !!selected && !!(selected + 1)
    // });

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={t('userVehicle.PublishVehicleDialog.DialogTitle')}>
            <VehicleForm ref={vehicleFormRef} onSubmit={onSubmit}></VehicleForm>
        </DetailsDialog>
    );
};
