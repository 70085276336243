import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Outlet, Link } from 'react-router-dom';
import { List, ListItemIcon, ListItemText, useTheme, Grid, ListItemButton, Container, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Person2OutlinedIcon from '@mui/icons-material/PersonOutlined';
import { ROUTES } from 'parameters';
import { AuthorizationContext, UserProvider } from 'context';
import { MainView } from './Skeleton.style';
import useNavigation from 'hooks/useNavigation';
import { SetPassword } from 'components';
import { LinkContainer, LogoContainer } from 'components/navbar/Navbar.styled';
import logoImg from '../assets/logo.png';

export const Skeleton = () => {
    const { t } = useTranslation();

    const { logoutUser, getUserInfo } = useContext(AuthorizationContext);

    const location = useLocation();

    const navigate = useNavigate();

    const nav = useNavigation(location.pathname.includes('app') ? 'app' : 'admin');

    const theme = useTheme();

    const classes = {
        root: {
            display: 'flex',
            backgroundColor: theme.palette.background.paper
        },
        appBar: {
            '&.MuiAppBar-root': {
                overflow: 'hidden',
                backgroundColor: theme.palette.primary.light,
                zIndex: theme.zIndex.drawer + 1,
                color: 'white',
                boxShadow: 'unset'
            }
        },
        content: {
            flexGrow: 1,
            transition: 'all 150ms ease-in-out',
            height: 'calc(100vh)',
            overflow: 'auto'
        },
        smallSideNav: {
            width: '50px'
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px'
        },
        logo: {
            display: 'flex',
            paddingLeft: '18px',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }
    };

    return (
        <Container sx={classes.root} maxWidth={false}>
            <List sx={classes.list}>
                <LinkContainer sx={classes.logo}>
                    <LogoContainer>
                        <Link to='/'>
                            <img src={logoImg} alt='logo' />
                        </Link>
                    </LogoContainer>
                </LinkContainer>
                {nav.map(navItem => (
                    <ListItemButton
                        sx={{
                            maxHeight: '56px',
                            borderRadius: '50px',
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.secondary.light,
                                span: {
                                    color: theme.palette.primary.light
                                },
                                '&:hover *': {
                                    color: theme.palette.secondary.light
                                }
                            }
                        }}
                        selected={navItem.selected}
                        key={navItem.text}
                        onClick={() => navigate(`${navItem.route}`)}>
                        <ListItemIcon>{navItem.icon}</ListItemIcon>
                        <ListItemText sx={{ marginLeft: '-18px' }} primary={t(navItem.text)} />
                    </ListItemButton>
                ))}

                <Divider />

                <ListItemButton
                    sx={{
                        maxHeight: '56px',
                        borderRadius: '50px',
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.secondary.light,
                            span: {
                                color: theme.palette.primary.light
                            },
                            '&:hover *': {
                                color: theme.palette.secondary.light
                            }
                        }
                    }}
                    selected={location.pathname.includes(ROUTES.userProfile)}
                    onClick={(e: any) => navigate(`${ROUTES.app}/${ROUTES.userProfile}`)}>
                    <ListItemIcon>
                        {location.pathname.includes(ROUTES.userProfile) && <PersonIcon />}
                        {!location.pathname.includes(ROUTES.userProfile) && <Person2OutlinedIcon />}
                    </ListItemIcon>
                    <ListItemText sx={{ marginLeft: '-18px' }} primary={t('skeleton.User')} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        maxHeight: '56px',
                        borderRadius: '50px',
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.secondary.light,
                            span: {
                                color: theme.palette.primary.light
                            },
                            '&:hover *': {
                                color: theme.palette.secondary.light
                            }
                        }
                    }}
                    onClick={(e: any) => logoutUser()}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ marginLeft: '-18px' }} primary={t('skeleton.LogOut')} />
                </ListItemButton>
            </List>

            <Grid sx={classes.content}>
                <UserProvider>
                    <MainView>
                        <Outlet />
                    </MainView>
                </UserProvider>
            </Grid>
            <SetPassword open={!!getUserInfo?.password_reset_required}></SetPassword>
        </Container>
    );
};
