import styled from '@emotion/styled';

export const HomeHero = styled(`div`)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    alignSelf: 'flex-start',
    width: '100%',
    marginTop: '90px',
    maxWidth: '60vw'
}));

export const HeroCallToAction = styled(`div`)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '64px 0'
}));

export const ButtonContainerHero = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '16px',
    height: '0%'
}));
