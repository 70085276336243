import React, { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components';
import VehicleForm from './VehicleForm';
import { PatchVehicle, GetVehicle } from 'services';
import { toast } from 'react-toastify';
import { IVehicleInput } from 'models';
import { UserContext } from 'context';
import { queryClient } from 'index';

import { useQuery } from 'react-query';

export interface NotificationDialogProps {
    open: boolean;
    selected?: number;
    onClose?: () => void;
    onOpen?: () => void;
}

export const VehicleEditDialog = (props: NotificationDialogProps) => {
    const { t } = useTranslation();
    const vehicleFormRef = useRef<any>(null);
    const { userId } = useContext(UserContext);
    const { onClose, open, onOpen, selected } = props;

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        vehicleFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: IVehicleInput) => {
        PatchVehicle({ data, id: selected })
            .then(res => {
                toast.success(t('Save Successful'));
            })
            .catch(err => {
                toast.error(`${t('Save Failed')}: ${err.message}`);
            })
            .finally(() => {
                queryClient.invalidateQueries(['user_vehicles', userId]);
                queryClient.invalidateQueries(['vehicle', selected]);
                handleClose();
            });
    };

    const vehicleQuery = useQuery(['vehicle', selected], () => GetVehicle({ id: selected }), {
        staleTime: 86400,
        enabled: !!selected && !!(selected + 1) && selected !== Infinity
    });

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={t('userVehicles.GridEditDetailsButton')}>
            <VehicleForm vehicleQuery={vehicleQuery} ref={vehicleFormRef} onSubmit={onSubmit}></VehicleForm>
        </DetailsDialog>
    );
};
