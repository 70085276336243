import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { FormSimpleStyled, TextFieldStyled } from 'components';
import { ResetPasswordUser } from 'models';
import { useTranslation } from 'react-i18next';
import KeyIcon from '@mui/icons-material/Key';
import { generateStrongPassword } from 'utils/getRandomPassword';

type Props = {
    onSubmit: (data: ResetPasswordUser) => void;
};

const PasswordResetForm = forwardRef((props: Props, ref) => {
    const formRef = useRef<any>(null);
    const { onSubmit } = props;
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        current_password: Yup.string().required(t('Current Password is required')),
        new_password: Yup.string()
            .required(t('Password is required'))
            .min(6, t('Password must be at least 6 characters'))
            .max(40, t('Password must not exceed 40 characters')),
        re_new_password: Yup.string()
            .label(t('Confirm Password'))
            .required()
            .oneOf([Yup.ref('new_password')], t('Passwords must match'))
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            current_password: '',
            new_password: '',
            re_new_password: ''
        },
        resolver: yupResolver(validationSchema)
    });

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            handleSubmit(onSubmit)();
        }
    }));

    const setPassword = (e: any) => {
        e.preventDefault();
        const password = generateStrongPassword();
        setValue('new_password', password);
        setValue('re_new_password', password);
    };

    return (
        <FormSimpleStyled ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='column' justifyContent='flex-start' alignItems='start' gap={1} py={1}>
                <Controller
                    name='current_password'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TextFieldStyled
                            onChange={onChange}
                            value={value}
                            required
                            id='current_password'
                            label={`${t('Current Password')}`}
                            error={errors.current_password ? true : false}
                            helperText={errors.current_password?.message ? errors.current_password?.message : null}
                        />
                    )}
                />
                <Controller
                    name='new_password'
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            {...field}
                            required
                            id='password'
                            label={`${t('New Password')}`}
                            autoComplete='current-password'
                            error={errors.new_password ? true : false}
                            helperText={errors.new_password?.message ? errors.new_password?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={setPassword} color='primary' aria-label='add password'>
                                            <KeyIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='re_new_password'
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            {...field}
                            required
                            id='re_new_password'
                            label={`${t('New Password Repeat')}`}
                            error={errors.re_new_password ? true : false}
                            helperText={errors.re_new_password?.message ? errors.re_new_password?.message : null}
                        />
                    )}
                />
            </Grid>
        </FormSimpleStyled>
    );
});

export default PasswordResetForm;
