export const GetColor = (status: number) => {
    switch (status) {
        case 1:
            return '#aaaaaa';
        case 2:
            return '#93b46e';
        case 3:
            return '#6eaab4';
        case 4:
            return '#816eb4';
        case 5:
            return '#b46e8b';
        case 6:
            return '#b46e6e';
        case 7:
            return '#b4ad6e';
        default:
            return '#aaaaaa';
    }
};
