import { lazy, Suspense } from 'react';
import { Home } from 'components';
import { AuthentificatedRoutes, Skeleton, AdminRoute, AuthorizedContextRoutes } from 'router';
import { ROUTES } from 'parameters';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GlobalProvider } from 'context';

const Login = lazy(() => import('components').then(module => ({ default: module.Login })));
const Activate = lazy(() => import('components').then(module => ({ default: module.Activate })));
const Forbidden = lazy(() => import('components').then(module => ({ default: module.Forbidden })));
const SessionCheck = lazy(() => import('components').then(module => ({ default: module.SessionCheck })));
const ForgotPassword = lazy(() => import('components').then(module => ({ default: module.ForgotPassword })));
const ForgotPasswordConfirm = lazy(() =>
    import('components').then(module => ({ default: module.ForgotPasswordConfirm }))
);
const Market = lazy(() => import('components').then(module => ({ default: module.Market })));
const NotDefined = lazy(() => import('components').then(module => ({ default: module.NotDefined })));
const PrivacyPolicy = lazy(() => import('components').then(module => ({ default: module.PrivacyPolicy })));
const Register = lazy(() => import('components').then(module => ({ default: module.Register })));
const ServerError = lazy(() => import('components').then(module => ({ default: module.ServerError })));
const TermsAndConditions = lazy(() => import('components').then(module => ({ default: module.TermsAndConditions })));
const UserProfile = lazy(() => import('components').then(module => ({ default: module.UserProfile })));
const Vehicle = lazy(() => import('components').then(module => ({ default: module.Vehicle })));
const Storage = lazy(() => import('components').then(module => ({ default: module.Storage })));
const MyTasks = lazy(() => import('components').then(module => ({ default: module.MyTasks })));
const Dashboard = lazy(() => import('components').then(module => ({ default: module.Dashboard })));
const AdminUsers = lazy(() => import('components').then(module => ({ default: module.AdminUsers })));
const AdminVehicle = lazy(() => import('components').then(module => ({ default: module.AdminVehicle })));
const AdminStorage = lazy(() => import('components').then(module => ({ default: module.AdminStorage })));

export const GlobalRouter = () => {
    return (
        <Router>
            <GlobalProvider>
                <Suspense fallback={<></>}>
                    <Routes>
                        {/* PUBLIC ROUTES */}
                        {/* Account routes */}
                        <Route path={ROUTES.register} element={<Register></Register>} />
                        <Route path={ROUTES.createAccount} element={<></>} />
                        <Route path={ROUTES.activateAccount} element={<Activate></Activate>} />
                        <Route path={ROUTES.forgotPassword} element={<ForgotPassword></ForgotPassword>} />
                        <Route path={ROUTES.createNewPassword} element={<></>} />
                        <Route
                            path={ROUTES.resetPasswordConfirm}
                            element={<ForgotPasswordConfirm></ForgotPasswordConfirm>}
                        />
                        <Route path={ROUTES.confirmEmailChange} element={<></>} />
                        <Route path={ROUTES.emailChangeConfirmed} element={<></>} />
                        {/* General routes */}

                        <Route path={ROUTES.contact} element={<></>} />
                        <Route path={ROUTES.privacy} element={<PrivacyPolicy></PrivacyPolicy>} />
                        <Route path={ROUTES.terms} element={<TermsAndConditions></TermsAndConditions>} />
                        {/* AUTHENTIFICATED ROUTES */}
                        <Route element={<AuthorizedContextRoutes />}>
                            <Route path={ROUTES.login} element={<Login></Login>} />
                            <Route path={ROUTES.home}>
                                <Route path=':link' element={<Home></Home>} />
                                <Route path='' element={<Home></Home>} />
                            </Route>
                            <Route element={<AuthentificatedRoutes />}>
                                <Route path={ROUTES.app} element={<Skeleton />}>
                                    {/* <Route index element={<Dashboard></Dashboard>} /> */}
                                    <Route index element={<Dashboard></Dashboard>} />
                                    <Route path={ROUTES.market} element={<Market></Market>} />
                                    <Route path={ROUTES.vehicle} element={<Vehicle></Vehicle>} />
                                    <Route path={ROUTES.storage} element={<Storage></Storage>} />
                                    <Route path={ROUTES.mytasks} element={<MyTasks></MyTasks>} />
                                    <Route path={ROUTES.userProfile} element={<UserProfile></UserProfile>} />
                                </Route>
                                <Route element={<AdminRoute />}>
                                    <Route path={ROUTES.admin} element={<Skeleton />}>
                                        <Route index element={<Navigate to={ROUTES.users} />} />
                                        <Route index path={ROUTES.users} element={<AdminUsers></AdminUsers>} />
                                        <Route path={ROUTES.vehicle} element={<AdminVehicle></AdminVehicle>} />
                                        <Route path={ROUTES.storage} element={<AdminStorage></AdminStorage>} />
                                        <Route path={ROUTES.userProfile} element={<UserProfile></UserProfile>} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path={ROUTES.forbidden} element={<Forbidden></Forbidden>} />
                        <Route path={ROUTES.session} element={<SessionCheck></SessionCheck>} />
                        <Route path={ROUTES.serverError} element={<ServerError></ServerError>} />
                        <Route path={ROUTES.notdefined} element={<NotDefined></NotDefined>} />
                        <Route path={ROUTES.nomatch} element={<Navigate to={ROUTES.notdefined} />} />
                    </Routes>
                </Suspense>
            </GlobalProvider>
        </Router>
    );
};
