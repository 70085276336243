import { IUserData, IUserProfile, ResetPasswordUser } from 'models';
import { USER_GET_CURRENT, USER_GET_PROFILE, USER_POST_PASSWORD, USER_POST_PROFILE } from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetCurrentUser = (): Promise<IUserData> => {
    let currentUser = privateAxios
        .get(USER_GET_CURRENT)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserData = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error);
            throw new Error('failed to fetch user');
        });

    return currentUser;
};

export const GetUser = ({ id }: { id: number }): Promise<IUserProfile> => {
    if (typeof id !== 'number' || id === Infinity || id === null || id === undefined) {
        console.trace('error');
        return Promise.reject('id is not a number');
    }
    let responseUser = privateAxios
        .get(`${USER_GET_PROFILE}${id}/`)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserProfile = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.trace('id', id);
            console.log('GetUser error:' + error);
            throw new Error('failed to get User');
        });

    return responseUser;
};

export const EditUser = ({ id, data }: { id: number; data: IUserProfile }): Promise<IUserProfile> => {
    let responseUser = privateAxios
        .put(`${USER_POST_PROFILE}${id}/`, data)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserProfile = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('EditUser error:' + error);

            throw new Error('failed to edit User');
        });

    return responseUser;
};

export const DeleteUser = ({ id }: { id: number }): Promise<IUserProfile> => {
    let responseUser = privateAxios
        .delete(`${USER_POST_PROFILE}${id}/`)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserProfile = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('EditUser error:' + error);

            throw new Error('failed to edit User');
        });

    return responseUser;
};

export const GetCurrentUserProfile = (): Promise<IUserData> => {
    let currentUser = privateAxios
        .get(USER_GET_PROFILE)
        .then((res: any) => {
            console.log('GetCurrentUserProfile', res);
            if (res?.data !== undefined) {
                let responseData: IUserData = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error);
            throw new Error('failed to fetch user');
        });

    return currentUser;
};

export const ResetUserPassword = ({ data }: { data: ResetPasswordUser }): Promise<any> => {
    let response = privateAxios
        .post(`${USER_POST_PASSWORD}`, data)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: any = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('Update password error:' + error);

            throw new Error('failed to update password');
        });

    return response;
};
