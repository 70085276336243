import React from 'react';
import { UserVehicleGrid } from './UserVehicleGrid';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrivateWrapper } from 'components';
import { VehicleAddDialog } from 'components/vehicle/VehicleAddDialog';

export const UserVehicle = () => {
    const { t } = useTranslation();

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    return (
        <>
            <PrivateWrapper
                title={t('skeleton.Vehicle')}
                buttonTitle={t('userVehicle.PublishVehicleDialog.DialogTitle')}
                action={handleDialogOpen}
                buttonDisabled={false}>
                <Grid container direction='column'>
                    <Grid
                        container
                        item={true}
                        direction='column'
                        justifyContent='flex-start'
                        alignItems='start'
                        gap={1}
                        xs={4}
                        p={1}>
                        <Typography variant='h6' gutterBottom>
                            {t('userVehicles.Vehicles')}
                        </Typography>
                        <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                            <UserVehicleGrid></UserVehicleGrid>
                        </Grid>
                    </Grid>
                </Grid>
            </PrivateWrapper>

            <VehicleAddDialog open={dialogOpen} onClose={handleClose} />
        </>
    );
};
