import { Grid, Typography } from '@mui/material';
import { PrivateWrapper } from 'components/ux/private-wrapper/PrivateWrapper';
import { useTranslation } from 'react-i18next';
import { AllAdminVehicleGrid } from './AllAdminVehicleGrid';

export function AdminVehicle() {
    const { t } = useTranslation();

    return (
        <>
            <PrivateWrapper
                title={t('adminVehicles.PageLabel')}
                buttonTitle={t('userVehicle.PublishVehicleDialog.DialogTitle')}
                buttonDisabled={false}>
                  <AllAdminVehicleGrid></AllAdminVehicleGrid>
            </PrivateWrapper>
        </>
    );
}
