import { IStorageViewModel, IStorageGrid, IStorageInput } from 'models';
import { STORAGE_GET_ADMIN_STORAGES, VEHICLE_GET_ADMIN_STORAGE_CREATE } from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetAdminStorages = (selectedUserId?: number): Promise<Array<IStorageViewModel>> => {
    const url =
        STORAGE_GET_ADMIN_STORAGES +
        (selectedUserId && selectedUserId !== Infinity ? '?user_account_id=' + selectedUserId : '');
    let storageList = privateAxios
        .get(url)
        .then(res => {
            let responseData: Array<IStorageViewModel> = res.data;
            let gridData: Array<IStorageGrid> = [];
            if (responseData !== null && responseData !== undefined) {
                for (let index = 0; index < responseData.length; index++) {
                    const storageItem = responseData[index] as IStorageViewModel;
                    gridData.push({
                        id: storageItem.id,
                        address: storageItem?.address ?? '',
                        city: storageItem?.city,
                        dimensions: storageItem?.dimensions ?? '',
                        classification: storageItem?.classification ?? 0
                    });
                }
            }
            return gridData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return storageList;
};

export const GetAdminSingleStorage = ({ id }: { id: number }): Promise<IStorageViewModel> => {
    const url = `${STORAGE_GET_ADMIN_STORAGES}${id}/`;
    let singleStorage = privateAxios
        .get(url)
        .then(res => {
            if (res?.data !== undefined) {
                let responseData: IStorageViewModel = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return singleStorage;
};

export const PostNewAdminStorage = (data: IStorageInput, selectedUserId: number): Promise<IStorageViewModel> => {
    let storages = privateAxios
        .post(`${VEHICLE_GET_ADMIN_STORAGE_CREATE}?user_account_id=${selectedUserId}`, data)
        .then(res => {
            let responseData: IStorageViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add storage');
        });

    return storages;
};

export const PatchAdminStorage = ({ data, id }: { data: IStorageInput; id: number }): Promise<IStorageViewModel> => {
    let stroage = privateAxios
        .put(`${STORAGE_GET_ADMIN_STORAGES}${id}/`, data)
        .then(res => {
            let responseData: IStorageViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add stroage');
        });

    return stroage;
};

export const DeleteAdminStorage = ({ id }: { id?: number }): Promise<IStorageViewModel> => {
    let stroage = privateAxios
        .delete(`${STORAGE_GET_ADMIN_STORAGES}${id}/`)
        .then(res => {
            let responseData: IStorageViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to delete stroage');
        });

    return stroage;
};
