export function generateStrongPassword(): string {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*()-=_+[]{}|;:,.<>?';

    let password = '';

    // Generate at least one character from each category
    password += getRandomCharacter(lowercase);
    password += getRandomCharacter(uppercase);
    password += getRandomCharacter(numbers);
    password += getRandomCharacter(symbols);

    // Generate remaining characters
    for (let i = 0; i < 8 - 4; i++) {
        const category = getRandomCategory();
        password += getRandomCharacter(category);
    }

    // Shuffle the password
    password = shuffleString(password);

    return password;
}

function getRandomCharacter(characters: string): string {
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
}

function getRandomCategory(): string {
    const categories = ['lowercase', 'uppercase', 'numbers', 'symbols'];
    const randomIndex = Math.floor(Math.random() * categories.length);
    const category = categories[randomIndex];

    switch (category) {
        case 'lowercase':
            return 'abcdefghijklmnopqrstuvwxyz';
        case 'uppercase':
            return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        case 'numbers':
            return '0123456789';
        case 'symbols':
            return '!@#$%^&*()-=_+[]{}|;:,.<>?';
        default:
            return '';
    }
}

function shuffleString(string: string): string {
    const characters = string.split('');
    for (let i = characters.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [characters[i], characters[j]] = [characters[j], characters[i]];
    }
    return characters.join('');
}
