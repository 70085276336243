import React, { useEffect, useMemo, useContext } from 'react';
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { GetAdminAllUsers } from 'services';
import { useQuery } from 'react-query';
import { DrawerSideWindow } from 'components';
import { UserContext } from 'context';
import { AdminUserEdit } from './AdminUserEdit';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_HR } from 'material-react-table/locales/hr';
import i18n from 'services/global/i18n.service';
import EditIcon from '@mui/icons-material/Edit';

export const AllUsersGrid = () => {
    const [openDetails, setDetails] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState<any>();
    const { t } = useTranslation();
    const { setSelectedUserId } = useContext(UserContext);

    const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
        { accessorKey: 'id', header: 'ID', size: 50 },
        { accessorKey: 'email', header: t('usersAdminGrid.email'), size: 130 },
        {
            accessorKey: 'last_login',
            header: t('usersAdminGrid.last_login'),
            size: 130,
            Cell: ({ row, cell }: any) => {
                return cell.getValue() ? dayjs(cell.getValue()).format('DD/MM/YYYY') : '';
            }
        },
        {
            accessorKey: 'is_active',
            header: t('usersAdminGrid.is_active'),
            size: 50,
            Cell: ({ row, cell }: any) => {
                return cell.getValue() ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },

        {
            accessorKey: 'created_by_superuser',
            header: t('usersAdminGrid.created_by_superuser'),
            size: 50,
            Cell: ({ row, cell }: any) => {
                return cell.getValue() ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },

        {
            accessorKey: 'is_blocked',
            header: t('usersAdminGrid.is_blocked'),
            size: 50,
            Cell: ({ row, cell }: any) => {
                return cell.getValue() ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },
        {
            accessorKey: 'is_deleted',
            header: t('usersAdminGrid.is_deleted'),
            size: 50,
            Cell: ({ row, cell }: any) => {
                return cell.getValue() ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },
        {
            accessorKey: 'created_at',
            header: t('usersAdminGrid.created_at'),
            size: 100,
            Cell: ({ row, cell }: any) => {
                return cell.getValue() ? dayjs(cell.getValue()).format('DD/MM/YYYY') : '';
            }
        },
        {
            accessorKey: 'updated_at',
            header: t('usersAdminGrid.updated_at'),
            size: 100,
            Cell: ({ row, cell }: any) => {
                return cell.getValue() ? dayjs(cell.getValue()).format('DD/MM/YYYY') : '';
            }
        }
    ], []);

    useEffect(() => {
        if (selectedRowID) {
            setDetails(true);
            setSelectedUserId(selectedRowID);
        }
        return () => {
            setSelectedUserId(Infinity);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowID]);

    const handleClose = () => {
        setDetails(false);
        setSelectedRow(undefined);
    };

    const { data, isError, isRefetching, isLoading } = useQuery(['admin_users'], () => GetAdminAllUsers(), {
        staleTime: 10000
    });

    const table = useMaterialReactTable({
        columns,
        data: data || ([] as any),
        initialState: { showColumnFilters: true },
        enableEditing: true,
        
        getRowId: row => row.id,
        localization: i18n.language === 'hr' ? MRT_Localization_HR : (MRT_Localization_EN as any),
        state: {
            isLoading: isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching
        },
        renderRowActions: ({ row, table }) => {
            const onClick = () => {
                let selectedID: number = row.original.id;
                setSelectedRow(selectedID);
            };

            return (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip title='Edit'>
                        <IconButton onClick={onClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        }
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <DrawerSideWindow title='userAdminEdit.title' open={openDetails} onClose={handleClose}>
                <AdminUserEdit></AdminUserEdit>
            </DrawerSideWindow>
        </>
    );
};
