import axios, { InternalAxiosRequestConfig } from 'axios';
import { STATUS_CODE_BAD_REQUEST, STATUS_CODE_UNAUTHORIZED } from 'parameters';
import { toast } from 'react-toastify';
//import { history } from "../../router/MyRouter";
import { GetAccessToken, RefreshTokenJWT, RemoveTokens, SetAccessToken } from 'services';

const apiUrl = process.env.REACT_APP_APP_BASE_URL;

const privateAxios = axios.create({
    baseURL: apiUrl
});

privateAxios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // const { origin } = new URL(apiUrl + config?.url);
        // const allowedOrigins = [apiUrl];
        const token = GetAccessToken();
        //if (allowedOrigins.includes(origin)) {
        if (token !== null) {
            // console.log('Axios interceptor token: ' + token);
            config!.headers!.authorization = `JWT ${token}`;
        }
        return config;
    },
    error => {
        console.log('Axios interceptor error ');
        return Promise.reject(error);
    }
);

// Add a response interceptor
privateAxios.interceptors.response.use(
    function (response) {
        // console.log('Axios interceptor response ' + JSON.stringify(response));
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    async function (error) {
        // const { setGlobalLoading } = useContext(GlobalContext);
        const originalRequest = error.config;
        // console.trace('Axios interceptor response error !!!!!', error);
        if (error?.response?.status === STATUS_CODE_UNAUTHORIZED && !originalRequest._retry) {
            originalRequest._retry = true;
            //TODO try refresh with token
            console.log('Axios interceptor response error - refreshing token', error);
            //setGlobalLoading(true);
            try {
                const token = await RefreshTokenJWT();
                console.log(token);
                if (!!token) {
                    SetAccessToken(token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    return privateAxios(originalRequest);
                } else {
                    RemoveTokens();
                    window.location.href = '/login';
                }

                //setGlobalLoading(false);

                return Promise.resolve(token);
            } catch (error) {
                RemoveTokens();
            }

            //RemoveUserDataFromStorage();
        } else if (error?.response?.status === STATUS_CODE_BAD_REQUEST) {
            // handle bad request
            let responseData = error.response.data;
            Object.keys(responseData).forEach(function (key: any) {
                let messageItem = responseData[key];
                if (Array.isArray(messageItem)) {
                    messageItem.forEach((message: string) => {
                        toast.error(message);
                    });
                } else {
                    toast.error(messageItem);
                }
            });
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export default privateAxios;
