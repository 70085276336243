import { ActivationRouteData } from 'models';
import { USER_ACTIVATE, GOOD_REQUEST, BAD_REQUEST, SERVER_ERROR } from 'parameters';
import publicAxios from 'services/global/publicAxios.service';

export const ActivateUserCall = (activationData: ActivationRouteData): Promise<string> =>
    publicAxios
        .post(USER_ACTIVATE, activationData)
        .then(res => {
            if (res.status === 204) {
                return GOOD_REQUEST;
            } else {
                throw new Error('Error validating.');
            }
        })
        .catch(error => {
            //return error data
            console.log(error);

            if (error?.response?.data !== undefined) {
                return BAD_REQUEST;
            }
            // something went wrong with request, error not handeled, this should not happen
            return SERVER_ERROR;
        });
