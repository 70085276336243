import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BytegaButton, DetailsDialog } from 'components';
import useConfirm from 'hooks/useConfirm';
import { queryClient } from 'index';
import { IMarketTaskFull } from 'models';
import { toast } from 'react-toastify';
import { CancelTask, CompleteTask, DeleteTask, PatchTask } from 'services';
import MarketForm from '../market/MarketForm';
import dayjs from 'dayjs';
import { TaskFilters, TaskTypes, TaskUserState } from 'parameters';
export interface TaskDetailsDialogProps {
    open: boolean;
    selected?: string;
    urlEdit: string;
    urlSingle: string;
    onClose?: () => void;
    onOpen?: () => void;
    task_type: TaskTypes;
    filter: TaskFilters;
    task_state?: TaskUserState;
}

export const TaskDetailsDialog = (props: TaskDetailsDialogProps) => {
    const { onClose, open, onOpen, selected, urlEdit, task_state, urlSingle, filter } = props;
    const marketFormRef = useRef<any>(null);
    const { t } = useTranslation();

    const [ConfirmationDialogCancel, confirmEventCancel] = useConfirm(
        t('myTasks.Confirm'),
        t('myTasks.ConfirmationCancel')
    );

    const [ConfirmationDialogDelete, confirmEventDelete] = useConfirm(
        t('myTasks.Confirm'),
        t('myTasks.ConfirmationDelete')
    );

    const [ConfirmationDialogComplete, confirmEventComplete] = useConfirm(
        t('myTasks.Confirm'),
        t('myTasks.ConfirmationComplete')
    );

    const preview = useMemo(() => {
        if (task_state === 'SUBMITTER') {
            if (filter === 'PUBLISHED') {
                return false;
            }
        }
        return true;
    }, [task_state, filter]);

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        marketFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: IMarketTaskFull) => {
        if (selected) {
            const data2 = {
                ...data,
                date_from: dayjs(data.date_from).format('YYYY-MM-DD'),
                date_to: dayjs(data.date_to).format('YYYY-MM-DD')
            } as IMarketTaskFull;

            PatchTask({ taskData: data2, id: selected, url: urlEdit })
                .then(res => {
                    toast.success(t('Save Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Save Failed')}: ${err.message}`);
                })
                .finally(() => {
                    handleClose();
                    queryClient.invalidateQueries({ queryKey: ['tasks'] });
                    queryClient.invalidateQueries(['task', selected]);
                });
        }
    };

    const onDelete = async () => {
        const ans = await confirmEventDelete();
        if (ans && selected) {
            DeleteTask({ id: selected, url: urlEdit })
                .then(res => {
                    toast.success(t('Delete Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Delete Failed')}: ${err.message}`);
                })
                .finally(() => {
                    handleClose();
                    queryClient.invalidateQueries({ queryKey: ['tasks'] });
                });
        }
    };

    const onCancel = async () => {
        const ans = await confirmEventCancel();
        if (ans && selected) {
            CancelTask({ id: selected, url: urlEdit })
                .then(res => {
                    toast.success(t('Delete Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Delete Failed')}: ${err.message}`);
                })
                .finally(() => {
                    handleClose();
                    queryClient.invalidateQueries({ queryKey: ['tasks'] });
                });
        }
    };

    const onComplete = async () => {
        const ans = await confirmEventComplete();
        if (ans && selected) {
            CompleteTask({ id: selected, url: urlEdit })
                .then(res => {
                    toast.success(t('Complete Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Complete Failed')}: ${err.message}`);
                })
                .finally(() => {
                    handleClose();
                    queryClient.invalidateQueries({ queryKey: ['tasks'] });
                });
        }
    };

    const getButtons = () => {
        if (task_state === 'SUBMITTER') {
            switch (filter) {
                case 'COMPLETED':
                case 'CANCELED_SUBMITTER':
                case 'CANCELED_ACCEPTOR':
                    return (
                        <BytegaButton variant='contained' onClick={onDelete} text={t('buttons.DeleteButtonLabel')} />
                    );

                case 'ACCEPTED':
                    <BytegaButton variant='text' onClick={onCancel} text={t('buttons.CancelButtonLabel')} />;
                    return (
                        <BytegaButton
                            variant='contained'
                            onClick={onComplete}
                            text={t('buttons.CompleteButtonLabel')}
                        />
                    );

                case 'PUBLISHED':
                    return <BytegaButton variant='text' onClick={onCancel} text={t('buttons.CancelButtonLabel')} />;

                default:
                    return <></>;
            }
        } else {
            switch (filter) {
                case 'ACCEPTED':
                case 'PUBLISHED':
                    return <BytegaButton variant='text' onClick={onCancel} text={t('buttons.CancelButtonLabel')} />;

                default:
                    return <></>;
            }
        }
    };

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={preview ? undefined : handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={t('myTasks.TaskDetails')}>
            <>
                <MarketForm
                    ref={marketFormRef}
                    onSubmit={onSubmit}
                    selected={selected}
                    url={urlSingle}
                    preview={preview}></MarketForm>
                {getButtons()}
                <ConfirmationDialogCancel />
                <ConfirmationDialogDelete />
                <ConfirmationDialogComplete />
            </>
        </DetailsDialog>
    );
};
