import { IVehicleViewModel, IVehicleGrid, IVehicleInput } from 'models';
import { VEHICLE_GET_USER_VEHICLES } from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetUserVehicles = (): Promise<Array<IVehicleGrid>> => {
    let taskList = privateAxios
        .get(VEHICLE_GET_USER_VEHICLES)
        .then(res => {
            let responseData: Array<IVehicleViewModel> = res.data;
            let gridData: Array<IVehicleGrid> = [];
            if (responseData !== null && responseData !== undefined) {
                for (let index = 0; index < responseData.length; index++) {
                    const vehicleItem = responseData[index] as IVehicleViewModel;
                    gridData.push({
                        id: vehicleItem.id,
                        vin: vehicleItem.vin ?? '',
                        vehicle_category: vehicleItem.vehicle_category ?? '',
                        vehicle_model: vehicleItem.vehicle_model ?? '',
                        vehicle_brand: vehicleItem.vehicle_brand ?? ''
                    });
                }
            }
            return gridData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return taskList;
};

export const GetVehicle = ({ id }: { id?: number }): Promise<IVehicleViewModel> => {
    let taskList = privateAxios
        .get(`${VEHICLE_GET_USER_VEHICLES}${id}/`)
        .then(res => {
            let responseData: IVehicleViewModel = res.data;

            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return taskList;
};

export const PostNewVehicle = ({ data }: { data: IVehicleInput }): Promise<IVehicleViewModel> => {
    let vehicle = privateAxios
        .post(VEHICLE_GET_USER_VEHICLES, data)
        .then(res => {
            let responseData: IVehicleViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add vehicle');
        });

    return vehicle;
};

export const PatchVehicle = ({ data, id }: { data: IVehicleInput; id?: number }): Promise<IVehicleViewModel> => {
    let vehicle = privateAxios
        .put(`${VEHICLE_GET_USER_VEHICLES}${id}/`, data)
        .then(res => {
            let responseData: IVehicleViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add vehicle');
        });

    return vehicle;
};

export const DeleteVehicle = ({ id }: { id?: number }): Promise<IVehicleViewModel> => {
    let vehicle = privateAxios
        .delete(`${VEHICLE_GET_USER_VEHICLES}${id}/`)
        .then(res => {
            let responseData: IVehicleViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to delete vehicle');
        });

    return vehicle;
};
