import axios from 'axios';
import { ACCESS_TOKEN_NAME, REFRESH_JWT_TOKEN, REFRESH_TOKEN_NAME, STATUS_CODE_OK, VERIFY_JWT_TOKEN } from 'parameters';

export const SetAccessToken = (token: string) => {
    localStorage.setItem(ACCESS_TOKEN_NAME, token);
};

export const SetRefreshToken = (token: string) => {
    localStorage.setItem(REFRESH_TOKEN_NAME, token);
};

export const GetAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_NAME);
};

export const GetRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN_NAME) || '';
};

export const RemoveTokens = () => {
    console.trace('RemoveTokens');
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
    localStorage.removeItem('userInfo');
};

export const RefreshTokenJWT = async (): Promise<string> => {
    const apiUrl = process.env.REACT_APP_APP_BASE_URL;

    // initializing new axios here without interceptors
    const Axios = axios.create({
        baseURL: apiUrl
    });

    const refreshToken: IRefreshToken = {} as IRefreshToken;

    refreshToken.refresh = GetRefreshToken();
    // try refresh token
    if (refreshToken.refresh) {
        return Axios.post(REFRESH_JWT_TOKEN, refreshToken)
            .then(async res => {
                console.log('REFRESH_JWT_TOKEN res', res);
                if (res.status === STATUS_CODE_OK) {
                    RemoveTokens();
                    SetAccessToken(res.data.access);
                    SetRefreshToken(refreshToken.refresh ?? '');
                    return res.data.access;
                } else {
                    throw new Error('REFRESH_JWT_TOKEN error code');
                }
            })
            .catch(error => {
                RemoveTokens();
                console.error('REFRESH_JWT_TOKEN error', error);
                throw new Error('REFRESH_JWT_TOKEN error');
            });
    } else {
        throw new Error('no token');
    }
};

export const VerifyTokenJWT = (): Promise<boolean> => {
    const apiUrl = process.env.REACT_APP_APP_BASE_URL;

    // initializing new axios here without interceptors
    const Axios = axios.create({
        baseURL: apiUrl
    });

    const refreshToken: IRefreshToken = {} as IRefreshToken;

    refreshToken.refresh = GetRefreshToken();
    // try refresh token
    if (refreshToken.refresh) {
        return Axios.post(VERIFY_JWT_TOKEN, refreshToken)
            .then(async res => {
                console.log('VERIFY_JWT_TOKEN res', res);
                return true;
            })
            .catch(error => {
                RemoveTokens();
                console.error('VERIFY_JWT_TOKEN error', error);
                throw new Error('VERIFY_JWT_TOKEN error');
            });
    } else {
        throw new Error('VERIFY_JWT_TOKEN error');
    }
};

export interface IRefreshToken {
    refresh: string | null;
}
