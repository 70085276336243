import { Grid, Typography } from '@mui/material';
import { PrivateWrapper } from 'components/ux/private-wrapper/PrivateWrapper';
import { useTranslation } from 'react-i18next';
import { AllAdminStorageGrid } from './AllAdminStorageGrid';

export function AdminStorage() {
    const { t } = useTranslation();

    return (
        <>
            <PrivateWrapper
                title={t('adminStorage.PageLabel')}
                buttonTitle={t('userStorage.PublishStorageDialog.DialogTitle')}
                buttonDisabled={false}>
                 <AllAdminStorageGrid></AllAdminStorageGrid>
            </PrivateWrapper>
        </>
    );
}
