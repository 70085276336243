import { ICity, ICountry, ILanguage, IService } from 'models';
import { BASE_GET_CITIES, BASE_GET_LANGUAGES, SERVICE_GET_SERVICES, USER_GET_COUNTRIES } from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetCities = (): Promise<ICity[]> => {
    let response = [] as ICity[];
    let cities = privateAxios
        .get(BASE_GET_CITIES)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: ICity[] = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);

            return response;
        });

    return cities;
};

export const GetCountries = (): Promise<ICountry[]> => {
    let response = [] as ICountry[];
    let countries = privateAxios
        .get(USER_GET_COUNTRIES)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: ICountry[] = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);

            return response;
        });

    return countries;
};

export const GetLanguages = (): Promise<ILanguage[]> => {
    let response = [] as ILanguage[];
    let languages = privateAxios
        .get(BASE_GET_LANGUAGES)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: ILanguage[] = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);

            return response;
        });

    return languages;
};

export const GetServices = (): Promise<IService[]> => {
    let response = [] as IService[];
    let services = privateAxios
        .get(SERVICE_GET_SERVICES)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IService[] = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);

            return response;
        });

    return services;
};
