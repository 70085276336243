import { Grid, Typography } from '@mui/material';
import { PrivateWrapper } from 'components/ux/private-wrapper/PrivateWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AllUsersGrid } from './AllUsersGrid';
import { UserAddDialog } from 'components/private-pages/admin/users/UserAddDialog';


export function AdminUsers() {
    const { t } = useTranslation();

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    return (
        <>
            <PrivateWrapper
                title={t('adminUser.PageLabel')}
                buttonTitle={t('adminUser.PublishUserDialog.DialogTitle')}
                action={handleDialogOpen}
                buttonDisabled={false}>
                <AllUsersGrid></AllUsersGrid>
            </PrivateWrapper>

            <UserAddDialog open={dialogOpen} onClose={handleClose} />
        </>
    );
}
