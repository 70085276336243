import { Container, styled } from '@mui/material';

export const MainView = styled(Container)(
    () => `
width: 100%;
height: 100%;
`
);

export const Section = styled('div')(() => ``);
