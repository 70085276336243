import { RESET_PASSWORD, RESET_PASSWORD_CONFIRM } from 'parameters';

import publicAxios from 'services/global/publicAxios.service';

export const ForgotPasswordCall = (data: { email: string }): Promise<boolean> => {
    let currentUser: Promise<boolean> = publicAxios
        .post(RESET_PASSWORD, data)
        .then(async () => {
            return true;
        })
        .catch(error => {
            //return error data
            console.error('ForgotPasswordCall error', error);
            throw new Error('ForgotPasswordCall error');
        });
    return currentUser;
};

// TODO model
export const ForgotPasswordConfirmCall = (data: any): Promise<boolean> => {
    let currentUser: Promise<boolean> = publicAxios
        .post(RESET_PASSWORD_CONFIRM, data)
        .then(async () => {
            return true;
        })
        .catch(error => {
            //return error data
            console.error('ForgotPasswordCall error', error);
            throw new Error('ForgotPasswordCall error');
        });
    return currentUser;
};
