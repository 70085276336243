import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components/ux/dialog/DetailsDialog';
import { ResetPasswordUser } from 'models';
import { toast } from 'react-toastify';
import { ResetUserPassword } from 'services';
import PasswordResetForm from './PasswordResetForm';
import { AuthorizationContext } from 'context';

export interface SetPasswordProps {
    open: boolean;
    onClose?: () => void;
}

export function SetPassword(props: SetPasswordProps) {
    const { onClose, open } = props;
    const { t } = useTranslation();

    const { setUserData } = useContext(AuthorizationContext);

    const resetPassFormRef = useRef<any>(null);

    const handleAccept = () => {
        resetPassFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: ResetPasswordUser) => {
        ResetUserPassword({ data })
            .then(() => {
                toast.success(t('Save Successful'));
            })
            .catch((err: any) => {
                toast.error(`${t('Save Failed')}: ${err.message}`);
            })
            .finally(() => {
                setUserData(undefined as any);
            });
    };

    return (
        <DetailsDialog open={open} onAccept={handleAccept} onClose={onClose} title={t('user.setPassword.DialogTitle')}>
            <PasswordResetForm ref={resetPassFormRef} onSubmit={onSubmit}></PasswordResetForm>
        </DetailsDialog>
    );
}
