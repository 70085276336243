import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordCall } from 'services';
import { useCallbackWithLoading } from 'hooks';
import { useForm, Controller } from 'react-hook-form';
import { UserManagementWrapper } from '../UserManagementWrapper';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'parameters';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BytegaButton, TextFieldStyled } from 'components';
import { ManagementFormWrapper } from '../UserManagementWrapper.styled';
import logoImg from '../../../assets/logo.png';

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [forgot, forgotPending] = useCallbackWithLoading(ForgotPasswordCall);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('Email is required')).email(t('Email is invalid'))
    });

    const {
        control,
        handleSubmit,

        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            email: ''
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: { email: string }) => {
        forgot({ email: data.email })
            .then(res => {
                toast.success('Request Sent');
                navigate(ROUTES.home);
            })
            .catch(err => {
                toast.error('Request Failed');
            });
    };

    return (
        <UserManagementWrapper>
            <ManagementFormWrapper onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction='column' justifyContent='center' alignItems='center' gap={4} p={1}>
                    <Grid container item direction='column' justifyContent='flex-start' alignItems='center'>
                        <img src={logoImg} alt='logo' />
                        <Typography variant='h6' component='h1'>
                            {t('resetpasswordModal.Label')}
                        </Typography>
                    </Grid>

                    <Controller
                        name='email'
                        control={control}
                        render={({ field }) => (
                            <TextFieldStyled
                                {...field}
                                required
                                id='email'
                                label={`${t('Email')}`}
                                error={errors.email ? true : false}
                                helperText={errors.email?.message ? errors.email?.message : null}
                                InputProps={{
                                    style: {
                                        borderRadius: '20px'
                                    }
                                }}
                            />
                        )}
                    />
                    <Grid
                        container
                        item
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        gap={1}
                        width={'50%'}>
                        <BytegaButton
                            className={`primary`}
                            type='submit'
                            variant='contained'
                            disabled={forgotPending}
                            text={t('forgotbutton')}
                            fullWidth={true}
                        />

                        <BytegaButton
                            variant='outlined'
                            type='reset'
                            disabled={forgotPending}
                            onClick={() => navigate(ROUTES.contact)}
                            text={t('I need help')}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </ManagementFormWrapper>
        </UserManagementWrapper>
    );
};
