import { styled } from '@mui/material';

export const TextWrapper = styled('div')(({ theme }) => ({
    padding: ' 0 16px',
    [theme.breakpoints.up('sm')]: {
        padding: 0
    }
}));

export const Title = styled('h5')(({ theme }) => ({
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    paddingBottom: '4px'
}));

export const Section = styled('section')(({ theme }) => ({
    padding: ' 8px 0 16px',
    opacity: '0.8',
    lineHeight: '24px',
    '& > *': {
        padding: '8px 0'
    }
}));
