import React, { useContext } from 'react';
import AdminUserProfileEdit from './AdminUserProfileEdit';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { AdminUserVehicleGrid } from './AdminUserVehicleGrid';
import { UserContext } from 'context';
import { Grid } from '@mui/material';
import { AdminUserStorageGrid } from './AdminUserStorageGrid';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

type AdminUserEditProps = {};

export const AdminUserEdit = (props: AdminUserEditProps) => {
    const [value, setValue] = React.useState(0);

    const { userId } = useContext(UserContext);

    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return userId ? (
        <></>
    ) : (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                    <Tab label={t('adminUserEdit.profile')} />
                    <Tab label={t('adminUserEdit.vehicle')} />
                    <Tab label={t('adminUserEdit.storage')} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <AdminUserProfileEdit />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                    <AdminUserVehicleGrid />
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                    <AdminUserStorageGrid />
                </Grid>
            </CustomTabPanel>
        </>
    );
};
