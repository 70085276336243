import { SignUpUser, UserType } from 'models';
import { CREATE_USER, USER_GET_USER_TYPES } from 'parameters';
import publicAxios from 'services/global/publicAxios.service';

export const SignUpUserCall = (userData: SignUpUser): Promise<SignUpUser> => {
    let signUpUser = publicAxios
        .post(CREATE_USER, userData)
        .then(async res => {
            if (res?.data !== undefined) {
                let responseData: SignUpUser = res.data;
                return responseData;
            } else {
                throw new Error('No data response from server upon registration');
            }
        })
        .catch(error => {
            console.log(error.data);
            return {} as SignUpUser;
        });
    return signUpUser;
};

export const GetUserTypesCall = async (languageCode: string | undefined): Promise<Array<UserType>> => {
    let userTypes = await publicAxios
        .get(USER_GET_USER_TYPES, {
            headers: {
                'Accept-Language': languageCode ?? 'en'
            }
        })
        .then(async res => {
            if (res?.data !== undefined) {
                let responseData: Array<UserType> = res.data;

                return responseData;
            }
            return [];
        })
        .catch(error => {
            //error on fetching user types
            console.log(error.data);

            return {} as Array<UserType>;
        });
    return userTypes !== undefined ? userTypes : ({} as Array<UserType>);
};
