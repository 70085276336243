import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography } from '@mui/material';

export function Footer() {
    const { t } = useTranslation();
    return (
        <Container>
            <Grid container direction='column' alignItems='left' py={12} gap={4}>
                <Grid item>
                    <Typography variant='h3' component='h3'>
                        {t('If you have any questions please contact us at:')}
                    </Typography>

                    <Typography variant='h4' component='p'>
                        <a href='mailto: info@bytegra-apps.com' type='email'>
                            info@bytegra-apps.com
                        </a>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant='body2' component='p'>
                        <Link to='/privacy-policy'>{t('Privacy Policy')}</Link>
                    </Typography>
                    <Typography variant='body2' component='p'>
                        <Link to='/terms-and-conditions'>{t('Terms and Conditions')}</Link>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant='body2' component='p'>
                        Copyright ©2024 Bytegra
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}
