import React, { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components';
import StorageForm from './StorageForm';
import { GetStorage, PatchStorage } from 'services';
import { toast } from 'react-toastify';
import { IStorageInput } from 'models';
import { UserContext } from 'context';
import { queryClient } from 'index';
import { useQuery } from 'react-query';

export interface NotificationDialogProps {
    open: boolean;
    selected?: number;
    onClose?: () => void;
    onOpen?: () => void;
}

export const StorageEditDialog = (props: NotificationDialogProps) => {
    const { onClose, open, onOpen, selected } = props;
    const storageFormRef = useRef<any>(null);
    const { userId } = useContext(UserContext);
    const { t } = useTranslation();

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        storageFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: IStorageInput) => {
        PatchStorage({ storageData: data, id: selected })
            .then(res => {
                toast.success(t('Save Successful'));
            })
            .catch(err => {
                toast.error(`${t('Login Failed')}: ${err.message}`);
            })
            .finally(() => {
                handleClose();
                queryClient.invalidateQueries(['user_storages', userId]);
                queryClient.invalidateQueries(['storage', selected]);
            });
    };

    const storageQuery = useQuery(['storage', selected], () => GetStorage({ id: selected }), {
        staleTime: 86400,
        enabled: !!selected && !!(selected + 1) && selected !== Infinity
    });

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={'Edit Storage'}>
            <StorageForm storageQuery={storageQuery} ref={storageFormRef} onSubmit={onSubmit}></StorageForm>
        </DetailsDialog>
    );
};
