import { IUserData, LoginResponse, LoginResponseError, LoginUser } from 'models';
import { CREATE_JWT_TOKEN, STATUS_CODE_OK, STATUS_CODE_UNAUTHORIZED } from 'parameters';
import { GetCurrentUser, RemoveTokens, SetAccessToken, SetRefreshToken } from 'services';
import publicAxios from 'services/global/publicAxios.service';

export const SignInUserCall = (userData: LoginUser): Promise<LoginResponse> => {
    let loginResponse = {} as LoginResponse;
    const currentUser: Promise<LoginResponse> = publicAxios
        .post(CREATE_JWT_TOKEN, userData)
        .then(async res => {
            if (res.status === STATUS_CODE_OK) {
                RemoveTokens();
                SetAccessToken(res.data.access);
                SetRefreshToken(res.data.refresh);
                let currentUserData: IUserData = await GetCurrentUser();
                loginResponse.currentUser = currentUserData;
                return loginResponse;
            } else {
                throw new Error('Server error');
            }
        })
        .catch(error => {
            //return error data
            if (error?.response?.status === STATUS_CODE_UNAUTHORIZED) {
                throw new Error(!!error?.response?.data?.detail ? error?.response?.data?.detail : 'Login unsuccessful');
            } else if (error?.response?.data !== undefined) {
                let responseData: LoginResponseError = error.response.data;

                loginResponse.detail = responseData.detail;
                return loginResponse;
            } else {
                throw new Error('Unknown error occured.');
            }
        });
    return currentUser;
};
