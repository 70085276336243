import React, { useContext, useMemo } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { GlobalContext, UserContext } from 'context';
import { GetAdminStorages } from 'services';
import { useQuery } from 'react-query';
import { StorageAdminEditDialog } from '../storage/StorageAdminEditDialog';
import { BytegaButton } from 'components';
import { StorageAddDialog } from 'components/storage/StorageAddDialog';

export const AdminUserStorageGrid = () => {
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [openNewDialog, setNewDialog] = React.useState(false);

    const [selectedRowID, setSelectedRow] = React.useState<number>(Infinity);
    const { t } = useTranslation();
    const { selectedUserId } = useContext(UserContext);
    const { cityListQuery } = useContext(GlobalContext);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 0, hideable: true },
        { field: 'address', headerName: t('marketGrid.Address'), width: 130 },
        {
            field: 'city',
            headerName: t('marketGrid.City'),
            width: 130,
            renderCell: (params: any) => {
                let selectedID: number = params.row.city;
                return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
            }
        },
        { field: 'dimensions', headerName: t('marketGrid.Dimensions'), width: 130 },
        {
            field: '',
            sortable: false,
            headerName: t('marketGrid.Action'),
            filterable: false,
            width: 150,
            renderCell: (params: any) => {
                const onClick = () => {
                    setDetailsDialog(true);
                    let selectedID: number = params.id;
                    setSelectedRow(selectedID);
                };

                return <Button onClick={onClick}>{t('grid.GridButtons.ActionButton')}</Button>;
            }
        }
    ];

    const handleClose = () => {
        setDetailsDialog(false);
        setNewDialog(false);
    };

    const storagesQuery = useQuery(['user_storages', selectedUserId], () => GetAdminStorages(selectedUserId), {
        staleTime: 10000,
        enabled: !!selectedUserId
    });

    const loading = useMemo(() => !storagesQuery.isLoading, [storagesQuery]);

    return (
        <>
            {loading ? (
                <>
                    <DataGrid rows={storagesQuery.data || []} columns={columns} />
                    <BytegaButton
                        variant='outlined'
                        onClick={() => setNewDialog(true)}
                        text={t('buttons.NewButtonLabel')}
                    />
                </>
            ) : (
                <CircularProgress color='inherit' />
            )}

            <StorageAddDialog open={openNewDialog} onClose={handleClose} isAdmin={true} />
            <StorageAdminEditDialog selected={selectedRowID} open={openDetailsDialog} onClose={handleClose} />
        </>
    );
};
