import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext, UserContext } from 'context';
import { DeleteStorage, GetUserStorages } from 'services';
import { useQuery } from 'react-query';
import { StorageEditDialog } from 'components/storage/StorageEditDialog';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_HR } from 'material-react-table/locales/hr';
import { toast } from 'react-toastify';
import { queryClient } from 'index';
import useConfirm from 'hooks/useConfirm';
import { Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const UserStorageGrid = () => {
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState<number>();
    const { t, i18n } = useTranslation();
    const { userId } = useContext(UserContext);
    const { cityListQuery } = useContext(GlobalContext);
    const [ConfirmationDialog, confirmEvent] = useConfirm(
        t('storageEditDialog.Confirm'),
        t('storageEditDialog.ConfirmationDelete')
    );

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            { accessorKey: 'id', header: 'ID', size: 0, hideable: true },
            { accessorKey: 'address', header: t('marketGrid.Address'), size: 130 },
            {
                accessorKey: 'city',
                header: t('marketGrid.City'),
                size: 130,
                Cell: ({ row }) => {
                    let selectedID: number = row.original.city;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            },
            { accessorKey: 'dimensions', header: t('marketGrid.Dimensions'), size: 130 }
        ],
        []
    );

    const handleClose = () => {
        setDetailsDialog(false);
    };

    const { data, isError, isRefetching, isLoading } = useQuery(['user_storages', userId], () => GetUserStorages(), {
        staleTime: 10000,
        enabled: !!userId
    });

    const table = useMaterialReactTable({
        columns,
        data: data || [],
        initialState: { showColumnFilters: true },
        enableEditing: true,
        
        getRowId: row => row.id,
        localization: i18n.language === 'hr' ? MRT_Localization_HR : (MRT_Localization_EN as any),
        state: {
            isLoading: isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching
        },
        renderRowActions: ({ row, table }) => {
            const onClick = () => {
                setDetailsDialog(true);
                let selectedID = row.original.id;
                setSelectedRow(selectedID);
            };

            const onDelete = async () => {
                const ans = await confirmEvent();
                if (ans) {
                    DeleteStorage({ id: row.original.id })
                        .then(res => {
                            toast.success(t('Delete Successful'));
                        })
                        .catch(err => {
                            toast.error(`${t('Delete Failed')}: ${err.message}`);
                        })
                        .finally(() => {
                            handleClose();
                            queryClient.invalidateQueries(['user_storages', userId]);
                        });
                }
            };

            return (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip title='Edit'>
                        <IconButton onClick={onClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete'>
                        <IconButton color='error' onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        }
    });

    return (
        <>
            <MaterialReactTable table={table} />
            <StorageEditDialog selected={selectedRowID} open={openDetailsDialog} onClose={handleClose} />
            <ConfirmationDialog />
        </>
    );
};
