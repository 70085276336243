import { useState } from 'react';
import { Autocomplete, Chip, Grid, TextField, Typography } from '@mui/material';
import { PrivateWrapper } from 'components';
import { TaskGrid } from './TaskGrid';
import { useTranslation } from 'react-i18next';
import {
    TaskFilters,
    taskFilters,
    taskTypes,
    TaskTypes,
    taskUserState,
    TaskUserState
} from '../../../../parameters/tasks';

export const MyTasks = () => {
    const { t } = useTranslation();

    const [selectedState, setselectedState] = useState<TaskUserState>('SUBMITTER');

    const [selectedGroup, setselectedGroup] = useState<TaskTypes>('TRANSPORTATION');

    const [selectedFilter, setselectedFilter] = useState<TaskFilters>('PUBLISHED');

    return (
        <PrivateWrapper title={t('myTasks.MyTasksLabel')}>
            <Grid
                container
                item={true}
                direction='column'
                justifyContent='flex-start'
                alignItems='start'
                gap={2}
                xs={12}
                p={1}>
                <Typography variant='h6' gutterBottom>
                    {t('buttons.FilterButtonLabel')}
                </Typography>
                <Grid container item={true} direction='row' justifyContent='flex-start' alignItems='start' gap={1}>
                    <Grid item xs={3}>
                        <Autocomplete
                            disableClearable
                            onChange={(event: any, newValue: any | null) => {
                                setselectedState(newValue.value);
                            }}
                            options={
                                taskUserState.map(el => {
                                    return { label: t(el).toUpperCase(), value: el };
                                }) || []
                            }
                            value={{ label: t(selectedState).toUpperCase(), value: selectedState }}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    label={t('taskDetails.UserState') + '*'}
                                    variant='outlined'
                                    sx={{
                                        '& fieldset': {
                                            borderRadius: '20px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            disableClearable
                            onChange={(event: any, newValue: any | null) => {
                                setselectedGroup(newValue.value);
                            }}
                            options={
                                taskTypes.map(el => {
                                    return { label: t(el).toUpperCase(), value: el };
                                }) || []
                            }
                            value={{ label: t(selectedGroup).toUpperCase(), value: selectedGroup }}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    label={t('taskDetails.Group') + '*'}
                                    variant='outlined'
                                    sx={{
                                        '& fieldset': {
                                            borderRadius: '20px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            disableClearable
                            onChange={(event: any, newValue: any | null) => {
                                setselectedFilter(newValue.value);
                            }}
                            options={
                                taskFilters.map(el => {
                                    return { label: t(el).toUpperCase(), value: el };
                                }) || []
                            }
                            value={{ label: t(selectedFilter).toUpperCase(), value: selectedFilter }}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    label={t('taskDetails.Filter') + '*'}
                                    variant='outlined'
                                    sx={{
                                        '& fieldset': {
                                            borderRadius: '20px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item={true}
                direction='column'
                justifyContent='flex-start'
                alignItems='start'
                gap={1}
                xs={12}
                p={1}>
                <Typography variant='h6' gutterBottom>
                    {t(selectedGroup)}
                </Typography>
                <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                    <TaskGrid task_state={selectedState} task_type={selectedGroup} filter={selectedFilter}></TaskGrid>
                </Grid>
            </Grid>
        </PrivateWrapper>
    );
};
