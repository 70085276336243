import { ROUTES } from 'parameters';
import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthorizationContext, GlobalContext } from 'context';

export const AdminRoute = () => {
    const { isAdmin } = useContext(AuthorizationContext);
    const { globalLoading } = useContext(GlobalContext);

    const location = useLocation();

    return globalLoading ? (
        <></>
    ) : isAdmin ? (
        <Outlet />
    ) : (
        <Navigate to={ROUTES.forbidden} replace state={{ from: location }} />
    );
};
