//API ENPOINTS

import { TaskFilters, TaskTypes, TaskUserState } from 'parameters';

//unsecured
export const CREATE_USER = '/auth/users/';
export const USER_ACTIVATE = '/auth/users/activation/';
export const RESET_PASSWORD = '/auth/users/reset_password/';
export const RESET_PASSWORD_CONFIRM = '/auth/users/reset_password_confirm/';
export const CREATE_JWT_TOKEN = '/auth/jwt/create/';
export const REFRESH_JWT_TOKEN = '/auth/jwt/refresh/';
export const VERIFY_JWT_TOKEN = '/auth/jwt/refresh/';

//secured
export const USER_GET_CURRENT = '/auth/users/me/';
export const USER_GET_PROFILE = '/user/profiles/';
export const USER_POST_PROFILE = '/user/profiles/';
export const USER_POST_PASSWORD = '/users/set_password/';

export const USER_GET_USER_TYPES = '/user/user_types/';
export const USER_GET_USER_INTERESTS = '/user/user_interests/'; // ne koristi se brijem

// admin
export const USER_ADMIN_USERS_ACCOUNTS = '/user/su_accounts/';
export const USER_ADMIN_USER_PROFILES = '/user/su_profiles/';
export const USER_POST_ADMIN_USER = '/user/su_create_account/';

//BASE
export const USER_GET_COUNTRIES = '/base/country/';
export const BASE_GET_CITIES = '/base/city/';
export const BASE_GET_LANGUAGES = '/base/language/';

export const SERVICE_GET_SERVICES = '/service/service_type/';

export const INTERESTS_GET_INTERESTS = '/interests/user_interests/'; //ne radi

//VEHICLE
export const VEHICLE_GET_USER_VEHICLES = '/vehicle/user_vehicles/';
export const VEHICLE_GET_ADMIN_VEHICLES = '/vehicle/su_vehicles/';
export const VEHICLE_GET_ADMIN_VEHICLE_CREATE = '/vehicle/su_vehicles_create/';

export const VEHICLE_GET_VEHICLE_CATEGORIES = '/vehicle/vehicle_categories/';
export const VEHICLE_GET_VEHICLE_MODELS = '/vehicle/vehicle_models/';
export const VEHICLE_GET_VEHICLE_BRANDS = '/vehicle/vehicle_brands/';
export const VEHICLE_GET_VEHICLE_FUEL_TYPES = '/vehicle/vehicle_fuel_types/';

//STORAGE
export const STORAGE_GET_USER_STORAGES = '/storage/user_storages/';
export const STORAGE_GET_ADMIN_STORAGES = '/storage/su_storages/';
export const VEHICLE_GET_ADMIN_STORAGE_CREATE = '/storage/su_storages_create/';

export const STORAGE_GET_STORAGE_CLASIFICATIONS = '/storage/storage_classifications/';

//TASKS

export const GET_TASK_TYPES = '/task/task_type/';
export const GET_TASK_CARGO_TYPES = '/task/cargo_type/';
export const GET_TASK_STATUSES = '/task/task_status/';

/* 
1 Published
2 Accepted
3 In progress
4 Completed
5 Canceled - Submitter
6 Canceled - Acceptor
*/

export const GET_TRANSPORTATION_TASKS = '/task/transportation_task/';
export const GET_STORAGE_TASKS = '/task/storage_task/';
export const GET_SHIPPING_TASKS = '/task/shipping_task/';

export const GET_TASKS = ({
    isSingle,
    task_type,
    task_state,
    filter,
    userId,
    selectedRowID
}: {
    task_type: TaskTypes;
    task_state?: TaskUserState;
    filter?: TaskFilters;
    userId?: number;
    selectedRowID?: string;
    isSingle?: boolean;
}) => {
    let base = '';
    if (task_state) {
        base = '/user/profiles/' + userId + '/';
    } else {
        base = '/task/';
    }

    let url = '';
    if (task_state) {
        url = 'user_' + task_state.toLowerCase() + '_' + task_type.toLowerCase() + '_tasks/';
    } else {
        url = task_type.toLowerCase() + (isSingle ? '_task/' : '_tasks/');
    }

    if (selectedRowID) {
        url = url + selectedRowID + '/';
    }

    let query = '';
    if (filter) {
        query = '?requested_task_status=' + filter.toLowerCase();
    }

    return base + url + query;
};

//MARKET
export const GET_TASKS_MARKET = '/task/market_tasks/ ';

//TOKENS
export const ACCESS_TOKEN_NAME = 'access_token';
export const REFRESH_TOKEN_NAME = 'refresh_token';

//STATUS CODES
export const STATUS_CODE_OK = 200;
export const STATUS_CODE_UNAUTHORIZED = 401;
export const STATUS_CODE_BAD_REQUEST = 400;
export const STATUS_CODE_HTTP_204_NO_CONTENT = 204;

//REQUEST STATUS
export const BAD_REQUEST = 'BAD_REQUEST';
export const GOOD_REQUEST = 'GOOD_REQUEST';
export const SERVER_ERROR = 'SERVER_ERROR';
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST';

//APP
export const APPLICATION_NAME = 'Cargo SaT';
