import React from 'react';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { GlobalRouter } from 'router';

const theme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: '#DCFFF6',
            paper: '#FFF'
        },
        primary: {
            main: '#1D5546',
            contrastText: '#DCFFF6'
        },
        secondary: {
            main: '#2C2C2C',
            light: '#DCFFF6',
            contrastText: '#DCFFF6'
        },
        error: {
            main: '#B30800'
        },
        success: {
            main: '#52B300'
        },
        info: {
            main: '#6100B3'
        },
        text: {
            primary: '#2C2C2C',
            secondary: '#00000'
        }
    },
    typography: {
        h1: {
            color: '#2C2C2C',
            fontWeight: '400'
        },
        h2: {
            color: '#2C2C2C'
        },
        h3: {
            color: '#2C2C2C'
        },
        body1: {
            color: '#2C2C2C'
        },
        button: {
            color: '#2C2C2C'
        }
    }
});

function App() {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer
                    position='bottom-right'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover
                    theme='light'
                />
                <GlobalRouter />
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
