import { css, Grid, GridProps, styled } from '@mui/material';

interface StyledGridProps extends GridProps {
    success?: boolean;
}

export const GridStyledWrapper = styled(Grid, {
    shouldForwardProp: prop => prop !== 'success'
})<StyledGridProps>(({ success, theme }) => ({
    height: '100vh',
    width: '100vw',
    padding: '150px 48px',
    backgroundColor: theme.palette.primary.light,
    border: 0,
    overflowX: 'hidden',
    position: 'relative',
    zIndex: 1,
    ...(success && css``)
}));

export const BackgroundDiv = styled('div', {
    shouldForwardProp: prop => prop !== 'success'
})<StyledGridProps>(({ theme }) => ({
    height: '100%',
    width: '20vw',
    backgroundColor: 'red',
    marginRight: '-40px',
    borderRadius: '40px 0 0 40px',
    background:
        "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%), url('images/user_management_bg.png')lightgray 50% / cover no-repeat",
    backgroundSize: 'cover',
    zIndex: 3
}));

export const ManagementFormWrapper = styled('form')(({ theme }) => ({
    minHeight: '100%',
    width: '50vw',
    backgroundColor: theme.palette.background.paper,
    padding: '10vh 10vw',
    borderRadius: '40px',
    zIndex: 3
}));
