import { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {
    GetAccessToken,
    GetCurrentUser,
    GetCurrentUserProfile,
    GetRefreshToken,
    RemoveTokens,
    VerifyTokenJWT
} from 'services';
import { ROUTES } from 'parameters';
import { IUserData } from '../../models';
import { AuthorizationContextState } from './AuthorizationContext.types';
import { GlobalContext } from 'context/globalContext/GlobalContext';

const contextDefaultValues: AuthorizationContextState = {
    logoutUser: () => {
        throw new Error('Logout user is not avaliable');
    },
    setUserData: () => {
        throw new Error('Set User Data is not avaliable');
    },
    getUserInfo: undefined,
    getUserProfile: undefined,
    isAuth: false,
    isAdmin: false
};

export const AuthorizationContext = createContext<AuthorizationContextState>(contextDefaultValues);

export const AuthorizationProvider = ({ children }: any) => {
    const [getUserInfo, setUserInfo] = useState<IUserData | undefined>(
        localStorage.getItem('userInfo') !== 'undefined' && localStorage.getItem('userInfo') !== null
            ? JSON.parse(localStorage.getItem('userInfo') || '')
            : undefined
    );

    const [getUserProfile, setUserProfile] = useState<IUserData | undefined>(undefined);
    const [isAuth, setAuthState] = useState<boolean>(false);
    const [isAdmin, setAdminState] = useState<boolean>(false);
    const [isTokenValid, setTokenValidState] = useState<boolean>(false);

    const { setGlobalLoading } = useContext(GlobalContext);

    const navigate = useNavigate();

    useEffect(() => {
        setGlobalLoading(true);
        if (!!getUserInfo?.id) {
            // verify token
            VerifyTokenJWT()
                .then((res: boolean) => {
                    setTokenValidState(res);
                })
                .catch(() => {
                    setAuthState(false);
                    setGlobalLoading(false);
                    setTokenValidState(false);
                    navigate(ROUTES.home);
                })
                .finally(() => {
                    setGlobalLoading(false);
                });
        } else {
            if (GetAccessToken() && GetRefreshToken()) {
                GetCurrentUser()
                    .then(async (res: any) => {
                        setUserData(res);
                        await GetCurrentUserProfile()
                            .then((res: any) => {
                                console.log('res', res);
                                setUserProfile(res);
                            })
                            .catch((error: any) => {
                                console.log('error:' + error.data);
                                navigate(ROUTES.home);
                                setGlobalLoading(false);
                            })
                            .finally(() => {
                                setGlobalLoading(false);
                            });
                    })
                    .catch((error: any) => {
                        console.log('error:' + error.data);
                        logoutUser();
                    })
                    .finally(() => {});
            } else {
                setGlobalLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserInfo]);

    useEffect(() => {
        if (!!getUserInfo?.id && !!!getUserProfile?.id) {
            // verify token
            setGlobalLoading(true);
            GetCurrentUserProfile()
                .then((res: any) => {
                    console.log('res', res);
                    setUserProfile(res[0]);
                })
                .catch((error: any) => {
                    console.log('error:' + error.data);
                })
                .finally(() => {
                    setGlobalLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserInfo, getUserProfile]);

    useEffect(() => {
        if (!!isTokenValid) {
            if (getUserInfo?.is_superuser) {
                setAdminState(true);
            }
            setAuthState(true);
            setGlobalLoading(false);
            return;
        }
        setAdminState(false);
        setAuthState(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserInfo?.id, isTokenValid, setGlobalLoading]);

    const setUserData = (data: IUserData) => {
        setUserInfo(data);
        localStorage.setItem('userInfo', JSON.stringify(data));
    };

    const logoutUser = () => {
        RemoveTokens();
        localStorage.setItem('userInfo', JSON.stringify({}));
        navigate(ROUTES.home);
        setTimeout(() => {
            setTokenValidState(false);
            setUserInfo(undefined);
            setGlobalLoading(false);
        }, 1);
    };

    return (
        <AuthorizationContext.Provider
            value={{
                logoutUser,
                setUserData,
                getUserInfo,
                getUserProfile,
                isAuth,
                isAdmin
            }}>
            {children}
        </AuthorizationContext.Provider>
    );
};
