import React from 'react';
import { Grid, Typography } from '@mui/material';
import { BytegaButton } from 'components';

type Props = {
    children: React.ReactNode;
    title: string;
    action?: () => void;
    buttonTitle?: string;
    buttonDisabled?: boolean;
};

export function PrivateWrapper({ children, title, action, buttonTitle, buttonDisabled = false }: Props) {
    return (
        <Grid container direction='column' justifyContent='center' alignItems='left' p={1}>
            <Grid item xs={12} container direction='row' justifyContent='space-between' alignItems='center' p={1}>
                <Typography component='h1' variant='h4'>
                    {title}
                </Typography>

                {!!buttonTitle && action && (
                    <BytegaButton
                        type='submit'
                        className={`primary`}
                        variant='contained'
                        text={buttonTitle}
                        disabled={buttonDisabled}
                        onClick={action}
                    />
                )}
            </Grid>
            <Grid item xs={12} container direction='row' justifyContent='flex-start' alignItems='left' p={1}>
                {children}
            </Grid>
        </Grid>
    );
}
