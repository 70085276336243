import { UserProfileForm } from 'components/private-pages/app/user/UserProfileForm';
import { BytegaButton } from 'components/ux/basic-components/Buttons';
import { UserContext } from 'context';
import useConfirm from 'hooks/useConfirm';
import { t } from 'i18next';
import { queryClient } from 'index';
import { UserFormValues } from 'models';
import { useContext, useRef } from 'react'; /*  */
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { BlockAdminUser, DeleteAdminUser, GetAdminUser, PatchAdminUserProfile, UnblockAdminUser } from 'services';

export default function AdminUserProfileEdit() {
    const userFormRef = useRef<any>(null);

    const [ConfirmationBlockDialog, confirmBlockEvent] = useConfirm(
        t('userEditDialog.Confirm'),
        t('userEditDialog.ConfirmationBlock')
    );

    const [ConfirmationDeleteDialog, confirmDeleteEvent] = useConfirm(
        t('userEditDialog.Confirm'),
        t('userEditDialog.ConfirmationDelete')
    );

    const { selectedUserId } = useContext(UserContext);

    const userQuery = useQuery(['AdminUserData', selectedUserId], () => GetAdminUser({ id: selectedUserId }), {
        staleTime: 86400,
        enabled: typeof selectedUserId === 'number'
    });

    const handleAccept = () => {
        userFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: UserFormValues) => {
        let profile = data.user;
        profile.service = data.services.filter(el => el.active).map(el => el.id);
        PatchAdminUserProfile({ data: profile, id: selectedUserId })
            .then(res => {
                toast.success(t('Save Successful'));
            })
            .catch(err => {
                toast.error(`${t('Login Failed')}: ${err.message}`);
            })
            .finally(() => {
                queryClient.invalidateQueries(['admin_users']);
            });
    };

    const onBlock = async () => {
        const ans = await confirmBlockEvent();
        if (ans) {
            BlockAdminUser({ id: selectedUserId })
                .then(res => {
                    toast.success(t('Block Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Block Failed')}: ${err.message}`);
                })
                .finally(() => {
                    queryClient.invalidateQueries(['AdminUserData', selectedUserId]);
                    queryClient.invalidateQueries(['admin_users']);
                });
        }
    };

    const onUnblockBlock = async () => {
        const ans = await confirmBlockEvent();
        if (ans) {
            UnblockAdminUser({ id: selectedUserId })
                .then(res => {
                    toast.success(t('Block Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Block Failed')}: ${err.message}`);
                })
                .finally(() => {
                    queryClient.invalidateQueries(['AdminUserData', selectedUserId]);
                    queryClient.invalidateQueries(['admin_users']);
                });
        }
    };

    const onDelete = async () => {
        const ans = await confirmDeleteEvent();
        if (ans) {
            DeleteAdminUser({ id: selectedUserId })
                .then(res => {
                    toast.success(t('Delete Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Delete Failed')}: ${err.message}`);
                })
                .finally(() => {
                    queryClient.invalidateQueries(['AdminUserData', selectedUserId]);
                    queryClient.invalidateQueries(['admin_users']);
                });
        }
    };

    return (
        <>
            <UserProfileForm
                userQuery={userQuery}
                ref={userFormRef}
                onSubmit={onSubmit}
                updatePending={false}></UserProfileForm>
            <BytegaButton variant='contained' onClick={handleAccept} text={t('buttons.SaveButtonLabel')} />
            <BytegaButton variant='contained' onClick={onBlock} text={t('buttons.BlockButtonLabel')} />
            <BytegaButton variant='contained' onClick={onUnblockBlock} text={t('buttons.UnblockButtonLabel')} />
            <BytegaButton variant='outlined' onClick={onDelete} text={t('buttons.DeleteButtonLabel')} />
            <ConfirmationBlockDialog />
            <ConfirmationDeleteDialog />
        </>
    );
}
