import { Select, SelectProps, styled, TextField, TextFieldProps } from '@mui/material';

// profile
export const TextFieldProfileStyled = styled(TextField)<TextFieldProps>(({ theme }) => ({
    width: '100%',
    zIndex: 50,
    borderRadius: '20px!important',
    overflow: 'visible'
}));

export const SelectProfileStyled = styled(Select)<SelectProps>(({ theme }) => ({
    minWidth: '150px',
    width: '100%',
    zIndex: 50,
    borderRadius: '20px!important',
    overflow: 'visible'
}));

export const FormSimpleStyled = styled('form')(({ theme }) => ({
    width: '100%'
}));

// regular

export const FormStyled = styled('form')(({ theme }) => ({
    width: '300px',
    marginLeft: 'auto',
    marginRight: 'auto'
}));

export const TextFieldStyled = styled(TextField)<TextFieldProps>(({ theme }) => ({
    width: '100%',
    minWidth: '150px',
    zIndex: 50,
    overflow: 'visible'
}));

export const SelectStyled = styled(Select)<SelectProps>(({ theme }) => ({
    width: '100%',
    minWidth: '150px',
    zIndex: 50,
    overflow: 'visible',
    borderRadius: '20px'
}));

export const TextStyled = styled('p')(({ theme }) => ({
    width: '100%',
    minWidth: '150px',
    margin: '0 0'
}));
