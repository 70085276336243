import React, { useContext, useMemo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context';
import { DeleteVehicle, GetUserVehicles } from 'services';
import { useQuery } from 'react-query';
import { VehicleEditDialog } from '../../../vehicle/VehicleEditDialog';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirm from 'hooks/useConfirm';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_HR } from 'material-react-table/locales/hr';
import { toast } from 'react-toastify';
import { queryClient } from 'index';

export const UserVehicleGrid = () => {
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState<number>(Infinity);
    const { t, i18n } = useTranslation();
    const { userId } = useContext(UserContext);

    const [ConfirmationDialog, confirmEvent] = useConfirm(
        t('userVehicle.Confirm'),
        t('userVehicle.ConfirmationDelete')
    );

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            { accessorKey: 'id', header: 'ID', size: 0, hideable: true },
            { accessorKey: 'vehicle_category', header: t('userVehicles.VehicleCategoryLabel'), size: 130 },
            { accessorKey: 'vehicle_brand', header: t('userVehicles.VehicleBrandLabel'), size: 130 },
            { accessorKey: 'vehicle_model', header: t('userVehicles.VehicleModelLabel'), size: 130 },
            { accessorKey: 'vin', header: t('userVehicles.VinLabel'), size: 130 }
        ],
        []
    );

    const handleClose = () => {
        setDetailsDialog(false);
    };

    const { data, isError, isRefetching, isLoading, refetch } = useQuery(
        ['user_vehicles', userId],
        () => GetUserVehicles(),
        {
            staleTime: 10000,
            enabled: !!userId
        }
    );

    const table = useMaterialReactTable({
        columns,
        data: data || [],
        initialState: { showColumnFilters: true },
        enableEditing: true,
        
        getRowId: row => row.id,
        localization: i18n.language === 'hr' ? MRT_Localization_HR : (MRT_Localization_EN as any),
        state: {
            isLoading: isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching
        },
        renderRowActions: ({ row, table }) => {
            const onClick = () => {
                setDetailsDialog(true);
                let selectedID = row.original.id;
                console.log('selectedID', row);
                setSelectedRow(selectedID);
            };

            const onDelete = async () => {
                const ans = await confirmEvent();
                if (ans) {
                    DeleteVehicle({ id: row.original.id })
                        .then(res => {
                            toast.success(t('Delete Successful'));
                        })
                        .catch(err => {
                            toast.error(`${t('Delete Failed')}: ${err.message}`);
                        })
                        .finally(() => {
                            handleClose();
                            queryClient.invalidateQueries(['user_vehicles', userId]);
                        });
                }
            };

            return (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip title='Edit'>
                        <IconButton onClick={onClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete'>
                        <IconButton color='error' onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        }
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <VehicleEditDialog selected={selectedRowID} open={openDetailsDialog} onClose={handleClose} />

            <ConfirmationDialog />
        </>
    );
};
