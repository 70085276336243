import { Footer, Navbar } from 'components';
import { MainWrapper, PageWrapper } from 'components/Main.styled';

type Props = {
    children: JSX.Element;
};

export function PublicWrapper(props: Props) {
    const { children } = props;
    return (
        <PageWrapper>
            <Navbar />
            <MainWrapper>{children}</MainWrapper>
            <Footer />
        </PageWrapper>
    );
}
