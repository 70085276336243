import { IAdminUser, IUserProfile, SignUpUser } from 'models';
import { USER_ADMIN_USERS_ACCOUNTS, USER_ADMIN_USER_PROFILES, USER_POST_ADMIN_USER } from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetAdminAllUsers = (): Promise<IAdminUser> => {
    let responseUser = privateAxios
        .get(`${USER_ADMIN_USERS_ACCOUNTS}`)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IAdminUser = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error);
            throw new Error('failed to get User');
        });

    return responseUser;
};

export const GetAdminUser = ({ id }: { id: number }): Promise<IUserProfile> => {
    let responseUser = privateAxios
        .get(`${USER_ADMIN_USER_PROFILES}${id}/`)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserProfile = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error);
            throw new Error('failed to get User');
        });

    return responseUser;
};

export const BlockAdminUser = ({ id }: { id: number }): Promise<IUserProfile> => {
    let responseUser = privateAxios
        .put(`${USER_ADMIN_USERS_ACCOUNTS}${id}/block_user/`)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserProfile = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error);
            throw new Error('failed to get User');
        });

    return responseUser;
};

export const UnblockAdminUser = ({ id }: { id: number }): Promise<IUserProfile> => {
    let responseUser = privateAxios
        .put(`${USER_ADMIN_USERS_ACCOUNTS}${id}/unblock_user/`)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserProfile = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error);
            throw new Error('failed to get User');
        });

    return responseUser;
};

export const DeleteAdminUser = ({ id }: { id: number }) => {
    let responseUser = privateAxios
        .put(`${USER_ADMIN_USERS_ACCOUNTS}${id}/delete_user/`)
        .then((res: any) => {
            if (res?.data !== undefined) {
                let responseData: IUserProfile = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error);
            throw new Error('failed to get User');
        });

    return responseUser;
};

export const PostNewUser = (userData: SignUpUser): Promise<SignUpUser> => {
    let storage = privateAxios
        .post(USER_POST_ADMIN_USER, userData)
        .then(res => {
            let responseData: SignUpUser = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add storage');
        });

    return storage;
};

export const PatchAdminUserProfile = ({ id, data }: { id: number; data: IUserProfile }): Promise<any> => {
    let storage = privateAxios
        .put(`${USER_ADMIN_USER_PROFILES}${id}/`, data)
        .then(res => {
            let responseData: any = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to edit storage');
        });

    return storage;
};
