import { createContext, useMemo, useState } from 'react';
import { GetUserTypesCall } from 'services/app/user-management/register.service';
import { useQuery, UseQueryResult } from 'react-query';
import { GlobalContextState } from './GlobalContext.types';
import { ICity, ITaskStatus, ITaskType, UserType } from 'models';
import { LinearProgress } from '@mui/material';
import { GetCities, GetTaskStatuses, GetTaskTypes } from 'services';

const contextDefaultValues: GlobalContextState = {
    userTypeQuery: {} as UseQueryResult<UserType[], unknown>,
    cityListQuery: {} as UseQueryResult<ICity[], unknown>,
    taskTypeListQuery: {} as UseQueryResult<ITaskType[], unknown>,
    taskStatusListQuery: {} as UseQueryResult<ITaskStatus[], unknown>,
    setGlobalLoading: () => {
        throw new Error('Global Loading not avaliable');
    },
    globalLoading: true,
    selectedRowCount: 5,
    updateSelectedRowCount: () => {
        throw new Error('Global Loading not avaliable');
    }
};

export const GlobalContext = createContext<GlobalContextState>(contextDefaultValues);

export const GlobalProvider = ({ children }: any) => {
    const [globalLoading, setGlobalLoading] = useState(false);

    const [selectedRowCount, updateSelectedRowCount] = useState(5);

    // set to 1 day, there is no need for this to be downloaded on every refresh
    const userTypeQuery = useQuery(['userTypes'], () => GetUserTypesCall('en'), { staleTime: 86400 });

    const taskTypeListQuery = useQuery(['task_type'], () => GetTaskTypes(), { staleTime: 86400 });

    const taskStatusListQuery = useQuery(['task_statuses'], () => GetTaskStatuses(), { staleTime: 86400 });

    const cityListQuery = useQuery(['cityList'], () => GetCities(), { staleTime: 86400 });

    const loading = useMemo(
        () => globalLoading || userTypeQuery.isLoading || taskTypeListQuery.isLoading || taskStatusListQuery.isLoading,
        [globalLoading, taskStatusListQuery.isLoading, taskTypeListQuery.isLoading, userTypeQuery.isLoading]
    );

    return (
        <GlobalContext.Provider
            value={{
                userTypeQuery,
                taskTypeListQuery,
                taskStatusListQuery,
                cityListQuery,
                setGlobalLoading,
                globalLoading,
                selectedRowCount,
                updateSelectedRowCount
            }}>
            {loading && <LinearProgress />}
            {children}
        </GlobalContext.Provider>
    );
};
