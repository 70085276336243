import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components';
import { PostNewUser } from 'services';
import { toast } from 'react-toastify';
import { SignUpUser } from 'models';
import { queryClient } from 'index';
import AddUserAccountForm from '../../../user/AddUserAccountForm';

export interface NotificationDialogProps {
    open: boolean;
    onClose?: () => void;
    onOpen?: () => void;
}

export const UserAddDialog = (props: NotificationDialogProps) => {
    const userFormRef = useRef<any>(null);

    const { onClose, open, onOpen } = props;

    const { t } = useTranslation();

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        userFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: SignUpUser) => {
        PostNewUser(data)
            .then(() => {
                toast.success(t('Save Successful'));
            })
            .catch((err: any) => {
                toast.error(`${t('Save Failed')}: ${err.message}`);
            })
            .finally(() => {
                handleClose();
                queryClient.invalidateQueries(['admin_users']);
            });
    };

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={t('userUser.PublishUserDialog.DialogTitle')}>
            <AddUserAccountForm ref={userFormRef} onSubmit={onSubmit}></AddUserAccountForm>
        </DetailsDialog>
    );
};
