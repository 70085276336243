import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';

type Props = {
    open: boolean;
    onClose: (e: boolean) => void;
    title: string;
    children: React.ReactNode;
};

export const DrawerSideWindow = (props: Props) => {
    const { children, title, ...rest } = props;

    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Drawer anchor='right' {...rest}>
                <Box sx={{ width: '66vw', maxWidth: '800px' }} role='presentation'>
                    <Grid
                        container
                        item={true}
                        direction='column'
                        justifyContent='flex-start'
                        alignItems='start'
                        gap={1}
                        xs={12}
                        p={1}>
                        <Typography variant='h6' gutterBottom>
                            {t(title)}
                        </Typography>
                        <Grid container item={true} direction='column'>
                            {children}
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </React.Fragment>
    );
};
