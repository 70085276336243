import { IStorageViewModel, IStorageGrid, IStorageInput } from 'models';
import { STORAGE_GET_USER_STORAGES } from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetUserStorages = (): Promise<Array<IStorageViewModel>> => {
    let taskList = privateAxios
        .get(STORAGE_GET_USER_STORAGES)
        .then(res => {
            let responseData: Array<IStorageViewModel> = res.data;
            let gridData: Array<IStorageGrid> = [];
            if (responseData !== null && responseData !== undefined) {
                for (let index = 0; index < responseData.length; index++) {
                    const storageItem = responseData[index] as IStorageViewModel;
                    gridData.push({
                        id: storageItem.id,
                        address: storageItem?.address ?? '',
                        city: storageItem?.city,
                        dimensions: storageItem?.dimensions ?? '',
                        classification: storageItem?.classification ?? 0
                    });
                }
            }
            return gridData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return taskList;
};

export const GetStorage = ({ id }: { id?: number }): Promise<IStorageViewModel> => {
    let taskList = privateAxios
        .get(`${STORAGE_GET_USER_STORAGES}${id}/`)
        .then(res => {
            let responseData: IStorageViewModel = res.data;

            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return taskList;
};

export const PostNewStorage = (storageData: IStorageInput): Promise<IStorageViewModel> => {
    let storage = privateAxios
        .post(STORAGE_GET_USER_STORAGES, storageData)
        .then(res => {
            let responseData: IStorageViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add storage');
        });

    return storage;
};

export const PatchStorage = ({
    storageData,
    id
}: {
    storageData: IStorageInput;
    id?: number;
}): Promise<IStorageViewModel> => {
    let storage = privateAxios
        .put(`${STORAGE_GET_USER_STORAGES}${id}/`, storageData)
        .then(res => {
            let responseData: IStorageViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to edit storage');
        });

    return storage;
};

export const DeleteStorage = ({ id }: { id?: number }): Promise<IStorageViewModel> => {
    let storage = privateAxios
        .delete(`${STORAGE_GET_USER_STORAGES}${id}/`)
        .then(res => {
            let responseData: IStorageViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to delete storage');
        });

    return storage;
};
