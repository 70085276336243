import { forwardRef, useMemo, useImperativeHandle, useRef, useContext } from 'react';
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem
} from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { FormSimpleStyled, SelectStyled, TextFieldStyled } from 'components';
import { SignUpUser, UserType } from 'models';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'context';
import KeyIcon from '@mui/icons-material/Key';
import { generateStrongPassword } from 'utils/getRandomPassword';

type Props = {
    onSubmit: (data: SignUpUser) => void;
};

const AddUserAccountForm = forwardRef((props: Props, ref) => {
    const formRef = useRef<any>(null);
    const { onSubmit } = props;
    const { t } = useTranslation();
    const { userTypeQuery } = useContext(GlobalContext);

    const validationSchema = Yup.object().shape({
        user_type: Yup.string().required(t('User type is required')),
        email: Yup.string().required(t('Email is required')).email(t('Email is invalid')),
        password: Yup.string()
            .required(t('Password is required'))
            .min(6, t('Password must be at least 6 characters'))
            .max(40, t('Password must not exceed 40 characters')),
        re_password: Yup.string()
            .label(t('Confirm Password'))
            .required()
            .oneOf([Yup.ref('password')], t('Passwords must match'))
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
            re_password: '',
            user_type: ''
        },
        resolver: yupResolver(validationSchema)
    });

    const loading = useMemo(() => !userTypeQuery.isLoading, [userTypeQuery]);

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            handleSubmit(onSubmit)();
        }
    }));

    const setPassword = (e: any) => {
        e.preventDefault();
        const password = generateStrongPassword();
        setValue('password', password);
        setValue('re_password', password);
    };

    return loading ? (
        <FormSimpleStyled ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='column' justifyContent='flex-start' alignItems='start' gap={1} py={1}>
                <Controller
                    name='user_type'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                            <InputLabel id={'user_type'}>{t('signUp.UserTypeLabel') + '*'}</InputLabel>
                            <SelectStyled
                                onChange={onChange}
                                value={value || ''}
                                variant='outlined'
                                defaultValue={''}
                                label={t('userProfile.UserTypeLabel') + '*'}
                                error={errors.user_type ? true : false}>
                                {userTypeQuery.data !== undefined ? (
                                    userTypeQuery.data?.map((userType: UserType) => (
                                        <MenuItem key={userType.id} value={userType.id}>
                                            {t(userType.user_type || '')}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value={0}>{t('dropdown.NoData')}</MenuItem>
                                )}
                            </SelectStyled>
                            <FormHelperText error={true}>
                                {errors.user_type?.message ? errors.user_type?.message : ''}
                            </FormHelperText>
                        </FormControl>
                    )}
                />
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TextFieldStyled
                            onChange={onChange}
                            value={value}
                            required
                            id='email'
                            label={`${t('Email')}`}
                            error={errors.email ? true : false}
                            helperText={errors.email?.message ? errors.email?.message : null}
                        />
                    )}
                />
                <Controller
                    name='password'
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            {...field}
                            required
                            id='password'
                            label={`${t('Password')}`}
                            type='password'
                            autoComplete='current-password'
                            error={errors.password ? true : false}
                            helperText={errors.password?.message ? errors.password?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={setPassword} color='primary' aria-label='add password'>
                                            <KeyIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='re_password'
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            {...field}
                            required
                            id='re_password'
                            label={`${t('Password Repeat')}`}
                            type='password'
                            error={errors.re_password ? true : false}
                            helperText={errors.re_password?.message ? errors.re_password?.message : null}
                        />
                    )}
                />
            </Grid>
        </FormSimpleStyled>
    ) : (
        <CircularProgress color='inherit' />
    );
});

export default AddUserAccountForm;
