import { Grid, Typography } from '@mui/material';
import { PrivateWrapper } from 'components/ux/private-wrapper/PrivateWrapper';
import { t } from 'i18next';
import React from 'react';
import { AnalyticsCurrentVisits } from './analytics-current-visits';
import { useTranslation } from 'react-i18next';
import { AnalyticsWebsiteVisits } from './analytics-website-visits';
import { _posts, _timeline } from 'utils/_mock';
import { AnalyticsNews } from './analytics-news';
import { AnalyticsOrderTimeline } from './analytics-order-timeline';

export function Dashboard() {
    const { t } = useTranslation();

    return (
        <PrivateWrapper title={t('dashboard.PageLabel')}>
            <Grid container spacing={3}>
                <Grid xs={12} item={true}>
                    <Typography variant='h4' sx={{ mb: { xs: 3, md: 5 } }}>
                        Hi, Welcome back 👋
                    </Typography>
                </Grid>

                <Grid xs={12} sm={6} md={4} item={true}>
                    <AnalyticsCurrentVisits
                        title='Tasks %'
                        chart={{
                            series: [
                                { label: t('Cargo'), value: 3500 },
                                { label: t('Transport'), value: 2500 },
                                { label: t('Storage'), value: 1500 }
                            ]
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={8} item={true}>
                    <AnalyticsWebsiteVisits
                        title='Task Overview'
                        subheader='(+43%) than last week'
                        chart={{
                            categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                            series: [
                                { name: 'Cargo', data: [30, 40, 25, 50, 49, 21, 70, 51] },
                                { name: 'Transport', data: [23, 12, 54, 61, 32, 56, 81, 19] },
                                { name: 'Storage', data: [23, 12, 54, 61, 32, 56, 81, 19] }
                            ]
                        }}
                    />
                </Grid>

                <Grid xs={12} md={6} lg={8} item={true}>
                    <AnalyticsNews title='News' list={_posts.slice(0, 5)} />
                </Grid>

                <Grid xs={12} md={6} lg={4} item={true}>
                    <AnalyticsOrderTimeline title='Order timeline' list={_timeline} />
                </Grid>
            </Grid>
        </PrivateWrapper>
    );
}
