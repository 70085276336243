import React, { useContext, useMemo } from 'react';
import { Box, Chip, IconButton, Tooltip } from '@mui/material';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AcceptTask, GetTasks } from 'services';
import { GetColor } from 'utils';
import { AuthorizationContext, GlobalContext } from 'context';
import { GET_TASKS, TaskFilters, TaskTypes, TaskUserState } from 'parameters';
import { MarketOfferDetailsDialog } from './MarketOfferDetailsDialog';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_HR } from 'material-react-table/locales/hr';
import InfoIcon from '@mui/icons-material/Info';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { ITaskType } from 'models';
import useConfirm from 'hooks/useConfirm';
import { toast } from 'react-toastify';

interface IStorageMarketGrid {
    task_type: TaskTypes;
    filter: TaskFilters;
    task_state?: TaskUserState;
}

export const MarketGrid = ({ task_type, filter, task_state }: IStorageMarketGrid) => {
    const { t, i18n } = useTranslation();
    const { getUserProfile } = useContext(AuthorizationContext);
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState(0);
    const { taskTypeListQuery, taskStatusListQuery, cityListQuery } = useContext(GlobalContext);

    const userId = useMemo(() => {
        return getUserProfile?.id ?? null;
    }, [getUserProfile]);

    const url = GET_TASKS({ task_type, task_state, filter, userId });

    const singleUrl = GET_TASKS({ isSingle: true, task_type, task_state, filter, userId, selectedRowID });

    const [ConfirmationDialog, confirmEvent] = useConfirm(
        t('marketOfferDetailsDialog.AcceptOffer'),
        t('marketOfferDetailsDialog.AcceptOfferConfirmation')
    );

    const { data, isError, isRefetching, isLoading } = useQuery(
        ['tasks', task_type, task_state, filter, userId],
        () => GetTasks({ url: url }),
        {
            staleTime: 10000,
            enabled: !!url
        }
    );

    const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
        const taskStorage: any = [
            {
                accessorKey: 'storageClass',
                header: t('marketGrid.StorageClass'),
                size: 130,

                Cell: ({ row }: any) => {
                    let selectedStorage = row.original.storage;
                    return `${selectedStorage.classification}`;
                }
            },
            {
                accessorKey: 'storageSize',
                header: t('marketGrid.StorageSize'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedStorage = row.original.storage;
                    return `${selectedStorage.dimensions}`;
                }
            },
            {
                accessorKey: 'city',
                header: t('marketGrid.City'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedStorage = row.original.storage;
                    let cityID = selectedStorage.city;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(cityID)).map(el => el.name);
                }
            }
        ];

        const taskTransport: any = [
            {
                accessorKey: 'date_from',
                header: t('marketGrid.PickupDate'),
                type: 'date',
                size: 200
            },
            {
                accessorKey: 'vehicle',
                header: t('marketGrid.Vehicle'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedVehicle = row.original.vehicle;
                    return `${selectedVehicle.vehicle_model} - ${selectedVehicle.vin}`;
                }
            },
            {
                accessorKey: 'location_from',
                header: t('marketGrid.City'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedID: number = row.original.location_from;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            }
        ];

        const taskShipping = [
            {
                accessorKey: 'location_from',
                header: t('marketGrid.From'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedID: number = row.original.location_from;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            },
            {
                accessorKey: 'location_to',
                header: t('marketGrid.To'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedID: number = row.original.location_to;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            },
            {
                accessorKey: 'date_from',
                header: t('marketGrid.PickupDate'),
                type: 'date',
                size: 200
            },
            {
                accessorKey: 'date_to',
                header: t('marketGrid.DeliveryDate'),
                type: 'date',
                size: 200
            }
        ];

        const taskBase = [
            { accessorKey: 'id', header: 'ID', size: 0, hideable: true },
            {
                accessorKey: 'task_type',
                header: t('marketGrid.TaskType'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedID: number = row.original.task_type;
                    console.log(
                        taskTypeListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name)[0]
                    );
                    return (
                        <Chip
                            label={t(
                                taskTypeListQuery.data
                                    ?.filter(el => Number(el.id) === Number(selectedID))
                                    .map(el => el.name)[0]
                                    .toUpperCase() || ''
                            )}
                            size='small'
                            sx={{
                                color: '#FFFFFF',
                                backgroundColor: GetColor(selectedID),
                                minsize: '100px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: '8px'
                            }}
                        />
                    );
                }
            },
            {
                accessorKey: 'task_status',
                header: t('marketGrid.TaskStatus'),
                size: 130,
                Cell: ({ row }: any) => {
                    let selectedID: number = row.original.task_status;
                    return (
                        <Chip
                            label={t(
                                taskStatusListQuery.data
                                    ?.filter(el => Number(el.id) === Number(selectedID))
                                    .map(el => el.name)[0]
                                    .toUpperCase() || ''
                            )}
                            size='small'
                            sx={{
                                color: '#FFFFFF',
                                backgroundColor: GetColor(selectedID),
                                minsize: '100px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: '8px'
                            }}
                        />
                    );
                }
            }
        ];
        switch (task_type) {
            case 'SHIPPING':
                return [...taskShipping, ...taskBase];
            case 'TRANSPORTATION':
                return [...taskTransport, ...taskBase];
            case 'STORAGE':
                return [...taskStorage, ...taskBase];
            default:
                return taskBase;
        }
    }, [cityListQuery.data, t, taskStatusListQuery.data, taskTypeListQuery.data, task_type]);

    const table = useMaterialReactTable({
        columns,
        data: data || [],
        initialState: { showColumnFilters: true },
        enableEditing: true,
        
        getRowId: row => row?.id,
        localization: i18n.language === 'hr' ? MRT_Localization_HR : (MRT_Localization_EN as any),
        state: {
            isLoading: isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching
        },
        renderRowActions: ({ row, table }) => {
            const onClick = () => {
                let selectedID: string = row.original?.id;
                let selectedStatus: number = row.original.task_status;
                console.log('selectedID', selectedID, selectedStatus);
                setSelectedRow(selectedID);
                setSelectedStatus(selectedStatus);
                setDetailsDialog(true);
            };

            const onAccept = async () => {
                const ans = await confirmEvent();
                if (ans && row.original.id) {
                    AcceptTask({
                        id: row.original.id,
                        task_code: taskTypeListQuery.data
                            ?.filter((el: ITaskType) => Number(el.id) === Number(row.original.task_type))
                            .map(el => el.code)[0]
                    })
                        .then(res => {
                            toast.success(t('notificationDialog.TaskAccepted'));
                        })
                        .catch(err => {
                            toast.error(`${t('Task Accepting Failed')}: ${err.message}`);
                        });
                }
            };

            return (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip title='Info'>
                        <IconButton onClick={onClick}>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Accept'>
                        <IconButton onClick={onAccept}>
                            <AddTaskIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        }
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <MarketOfferDetailsDialog
                selected={selectedRowID}
                open={openDetailsDialog}
                urlSingle={singleUrl}
                view={selectedStatus !== 1 ? true : false}
                onClose={() => {
                    setDetailsDialog(false);
                    setSelectedRow('');
                }}
            />
            <ConfirmationDialog />
        </>
    );
};
