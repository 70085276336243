import React, { useContext, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context';
import { GetAdminVehicles } from 'services';
import { useQuery } from 'react-query';
import { VehicleAdminEditDialog } from '../vehicle/VehicleAdminEditDialog';
import { BytegaButton } from 'components/ux/basic-components/Buttons';
import { VehicleAddDialog } from 'components/vehicle/VehicleAddDialog';

export const AdminUserVehicleGrid = () => {
    const [openDetailsDialog, setDetailsDialog] = useState(false);
    const [openNewDialog, setNewDialog] = useState(false);
    const [selectedRowID, setSelectedRow] = useState<number>(Infinity);
    const { t } = useTranslation();
    const { selectedUserId } = useContext(UserContext);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 0, hideable: true },
        { field: 'vehicle_category', headerName: t('userVehicles.VehicleCategoryLabel'), width: 130 },
        { field: 'vehicle_brand', headerName: t('userVehicles.VehicleBrandLabel'), width: 130 },
        { field: 'vehicle_model', headerName: t('userVehicles.VehicleModelLabel'), width: 130 },
        { field: 'vin', headerName: t('userVehicles.VinLabel'), width: 130 },
        {
            field: '',
            sortable: false,
            headerName: t('marketGrid.Action'),
            filterable: false,
            width: 150,
            renderCell: (params: any) => {
                const onClick = () => {
                    setDetailsDialog(true);
                    let selectedID: number = params.row.id;
                    setSelectedRow(selectedID);
                };

                return <Button onClick={onClick}>{t('grid.GridButtons.ActionButton')}</Button>;
            }
        }
    ];

    const handleClose = () => {
        setDetailsDialog(false);
        setNewDialog(false);
    };

    const vehiclesQuery = useQuery(['admin_vehicles', selectedUserId], () => GetAdminVehicles(selectedUserId), {
        staleTime: 10000,
        enabled: !!selectedUserId
    });

    const loading = useMemo(() => !vehiclesQuery.isLoading, [vehiclesQuery]);

    return (
        <>
            {loading ? (
                <>
                    <DataGrid rows={vehiclesQuery.data || []} columns={columns} />
                    <BytegaButton
                        variant='outlined'
                        onClick={() => setNewDialog(true)}
                        text={t('buttons.NewButtonLabel')}
                    />
                </>
            ) : (
                <CircularProgress color='inherit' />
            )}

            <VehicleAddDialog open={openNewDialog} onClose={handleClose} isAdmin={true} />
            <VehicleAdminEditDialog selected={selectedRowID} open={openDetailsDialog} onClose={handleClose} />
        </>
    );
};
