import type { CardProps } from '@mui/material/Card';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { useTheme, alpha as hexAlpha } from '@mui/material/styles';
import { ChartOptions, useChart, Chart } from 'components/chart';

// ----------------------------------------------------------------------

type Props = CardProps & {
    title?: string;
    subheader?: string;
    chart: {
        colors?: string[];
        categories?: string[];
        series: {
            name: string;
            data: number[];
        }[];
        options?: ChartOptions;
    };
};

export function AnalyticsWebsiteVisits({ title, subheader, chart, ...other }: Props) {
    const theme = useTheme();

    const chartColors = chart.colors ?? [theme.palette.primary.dark, hexAlpha(theme.palette.primary.light, 0.64)];

    const chartOptions = useChart({
        colors: chartColors,
        stroke: {
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: chart.categories
        },
        legend: {
            show: true
        },
        tooltip: {
            y: {
                formatter: (value: number) => `${value} visits`
            }
        },
        ...chart.options
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <Chart
                type='bar'
                series={chart.series}
                options={chartOptions}
                height={364}
                sx={{ py: 2.5, pl: 1, pr: 2.5 }}
            />
        </Card>
    );
}
