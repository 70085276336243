import React, { useContext, useEffect } from 'react';
import { Grid, MenuItem, FormControl, InputLabel, FormHelperText, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthorizationContext, GlobalContext } from 'context';
import { SignUpUserCall } from 'services';
import { useCallbackWithLoading } from 'hooks';
import { useForm, Controller } from 'react-hook-form';
import { SignUpUser, UserType } from 'models';
import { UserManagementWrapper } from '../UserManagementWrapper';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'parameters';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { BytegaButton, SelectStyled, TextFieldStyled } from 'components';
import { ManagementFormWrapper } from '../UserManagementWrapper.styled';
import logoImg from '../../../assets/logo.png';

export const Register = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isAuth } = useContext(AuthorizationContext);
    const { userTypeQuery } = useContext(GlobalContext);
    const [register, registerPending] = useCallbackWithLoading(SignUpUserCall);

    const validationSchema = Yup.object().shape({
        user_type: Yup.string().required(t('User type is required')),
        email: Yup.string().required(t('Email is required')).email(t('Email is invalid')),
        password: Yup.string()
            .required(t('Password is required'))
            .min(6, t('Password must be at least 6 characters'))
            .max(40, t('Password must not exceed 40 characters')),
        re_password: Yup.string()
            .label(t('Confirm Password'))
            .required()
            .oneOf([Yup.ref('password')], t('Passwords must match'))
    });

    const {
        control,
        handleSubmit,

        formState: { errors }
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
            re_password: '',
            user_type: ''
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: SignUpUser) => {
        register({
            email: data.email,
            password: data.password,
            re_password: data.re_password,
            user_type: data.user_type
        })
            .then(res => {
                toast.success(t('Registration Successful'));
                navigate(ROUTES.home);
            })
            .catch(err => {
                toast.error(t('Registration Failed'));
            });
    };

    useEffect(() => {
        if (isAuth) {
            toast.info(t('Already logged in'));
            navigate(ROUTES.app);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    return (
        <UserManagementWrapper>
            {!!userTypeQuery.data?.length && (
                <ManagementFormWrapper onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction='column' justifyContent='center' alignItems='center' gap={4} p={1}>
                        <Grid container item direction='column' justifyContent='flex-start' alignItems='center'>
                            <img src={logoImg} alt='logo' />
                            <Typography variant='h6' component='h1'>
                                {t('signUp.Label')}
                            </Typography>
                        </Grid>
                        <Controller
                            name='user_type'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth>
                                    <InputLabel id={'user_type'}>{t('signUp.UserTypeLabel') + '*'}</InputLabel>
                                    <SelectStyled
                                        onChange={onChange}
                                        value={value || ''}
                                        variant='outlined'
                                        defaultValue={!!userTypeQuery.data.length ? userTypeQuery.data[0].id : ''}
                                        label={t('userProfile.UserTypeLabel') + '*'}
                                        error={errors.user_type ? true : false}>
                                        {userTypeQuery.data !== undefined ? (
                                            userTypeQuery.data?.map((userType: UserType) => (
                                                <MenuItem key={userType.id} value={userType.id}>
                                                    {t(userType.user_type || '')}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value={0}>{t('dropdown.NoData')}</MenuItem>
                                        )}
                                    </SelectStyled>
                                    <FormHelperText error={true}>
                                        {errors.user_type?.message ? errors.user_type?.message : ''}
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name='email'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextFieldStyled
                                    onChange={onChange}
                                    value={value}
                                    required
                                    id='email'
                                    label={`${t('Email')}`}
                                    error={errors.email ? true : false}
                                    helperText={errors.email?.message ? errors.email?.message : null}
                                    InputProps={{
                                        style: {
                                            borderRadius: '20px'
                                        }
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name='password'
                            control={control}
                            render={({ field }) => (
                                <TextFieldStyled
                                    {...field}
                                    required
                                    id='password'
                                    label={`${t('Password')}`}
                                    type='password'
                                    autoComplete='current-password'
                                    error={errors.password ? true : false}
                                    helperText={errors.password?.message ? errors.password?.message : null}
                                    InputProps={{
                                        style: {
                                            borderRadius: '20px'
                                        }
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name='re_password'
                            control={control}
                            render={({ field }) => (
                                <TextFieldStyled
                                    {...field}
                                    required
                                    id='re_password'
                                    label={`${t('Password Repeat')}`}
                                    type='password'
                                    error={errors.re_password ? true : false}
                                    helperText={errors.re_password?.message ? errors.re_password?.message : null}
                                    InputProps={{
                                        style: {
                                            borderRadius: '20px'
                                        }
                                    }}
                                />
                            )}
                        />

                        <Grid
                            container
                            item
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            gap={1}
                            width={'50%'}>
                            <BytegaButton
                                type='submit'
                                variant='contained'
                                disabled={registerPending}
                                text={t('Register')}
                                fullWidth={true}
                            />
                            <BytegaButton
                                variant='outlined'
                                type='reset'
                                disabled={registerPending}
                                onClick={() => navigate(ROUTES.login)}
                                text={t('Already have account?')}
                                fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                </ManagementFormWrapper>
            )}
        </UserManagementWrapper>
    );
};
