import Grid from '@mui/material/Grid';
import { MainWrapper, ContentWrapper, DateText } from 'components/Main.styled';
import { useTranslation } from 'react-i18next';
import { Section, TextWrapper, Title } from './TermsOfService.styled';

export const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <MainWrapper>
            <TextWrapper>
                <h1>{t('privacyPolicy.headline')}</h1>
                <ContentWrapper>
                    <DateText>LAST UPDATED: 08.11.2022.</DateText>
                    <Title>{t('privacyPolicy.section.1.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.1.1')}</p>
                        <p>{t('privacyPolicy.section.1.2')}</p>
                        <p>{t('privacyPolicy.section.1.3')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.2.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.2.1')}</p>
                    </Section>
                    <Section>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <p>{t('privacyPolicy.section.2.1.a.title')}</p>
                            </Grid>
                            <Grid item xs={9}>
                                <p>{t('privacyPolicy.section.2.1.a.text')}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>{t('privacyPolicy.section.2.1.b.title')}</p>
                            </Grid>
                            <Grid item xs={9}>
                                <p>{t('privacyPolicy.section.2.1.b.text')}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>{t('privacyPolicy.section.2.1.c.title')}</p>
                            </Grid>
                            <Grid item xs={9}>
                                <p>{t('privacyPolicy.section.2.1.c.text')}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>{t('privacyPolicy.section.2.1.d.title')}</p>
                            </Grid>
                            <Grid item xs={9}>
                                <p>{t('privacyPolicy.section.2.1.d.text')}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>{t('privacyPolicy.section.2.1.e.title')}</p>
                            </Grid>
                            <Grid item xs={9}>
                                <p>{t('privacyPolicy.section.2.1.e.text')}</p>
                            </Grid>
                        </Grid>
                    </Section>
                    <Title>{t('privacyPolicy.section.3.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.3.1')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.4.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.4.1')}</p>
                        <p>
                            <ol type='a'>
                                <li>{t('privacyPolicy.section.4.1.a')}</li>
                                <li>{t('privacyPolicy.section.4.1.b')}</li>
                                <li>{t('privacyPolicy.section.4.1.c')}</li>
                            </ol>
                        </p>
                        <p>{t('privacyPolicy.section.4.2')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.5.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.5.1')}</p>
                        <p>
                            <ol type='a'>
                                <li>{t('privacyPolicy.section.5.1.a')}</li>
                                <li>{t('privacyPolicy.section.5.1.b')}</li>
                            </ol>
                        </p>
                    </Section>
                    <Title>{t('privacyPolicy.section.6.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.6.1')}</p>
                        <p>{t('privacyPolicy.section.6.2')}</p>
                        <p>{t('privacyPolicy.section.6.3')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.7.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.7.1')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.8.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.8.1')}</p>
                        <p>
                            <ol type='a'>
                                <li>{t('privacyPolicy.section.8.1.a')}</li>
                                <li>{t('privacyPolicy.section.8.1.b')}</li>
                                <li>{t('privacyPolicy.section.8.1.c')}</li>
                                <li>{t('privacyPolicy.section.8.1.d')}</li>
                                <li>{t('privacyPolicy.section.8.1.e')}</li>
                                <li>{t('privacyPolicy.section.8.1.f')}</li>
                                <li>{t('privacyPolicy.section.8.1.g')}</li>
                                <li>{t('privacyPolicy.section.8.1.h')}</li>
                            </ol>
                        </p>
                        <p>{t('privacyPolicy.section.8.2')}</p>
                        <p>{t('privacyPolicy.section.8.3')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.9.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.9.1')}</p>
                        <p>{t('privacyPolicy.section.9.2')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.10.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.10.1')}</p>
                    </Section>
                    <Title>{t('privacyPolicy.section.11.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.11.1')}</p>
                        <p>
                            <ol type='a'>
                                <li>{t('privacyPolicy.section.11.1.a')}</li>
                                <li>{t('privacyPolicy.section.11.1.b')}</li>
                            </ol>
                        </p>
                    </Section>
                    <Title>{t('privacyPolicy.section.12.headline')}</Title>
                    <Section>
                        <p>{t('privacyPolicy.section.12.1')}</p>
                    </Section>
                </ContentWrapper>
            </TextWrapper>
        </MainWrapper>
    );
};
