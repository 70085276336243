import React, { useContext, useMemo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'context';
import { GetAdminStorages } from 'services';
import { useQuery } from 'react-query';
import { StorageAdminEditDialog } from './StorageAdminEditDialog';
import { MRT_ColumnDef, useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_HR } from 'material-react-table/locales/hr';
import i18n from 'services/global/i18n.service';
import EditIcon from '@mui/icons-material/Edit';

export const AllAdminStorageGrid = () => {
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState<number>(Infinity);
    const { t } = useTranslation();
    const { cityListQuery } = useContext(GlobalContext);

    const handleClose = () => {
        setDetailsDialog(false);
    };

    const { data, isError, isRefetching, isLoading } = useQuery(['admin_storages'], () => GetAdminStorages(), {
        staleTime: 10000
    });

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            { accessorKey: 'id', header: 'ID', size: 50 },
            {
                accessorKey: 'user',
                header: t('marketGrid.User'),
                size: 130,
                Cell: ({ row, cell }: any) => {
                    let selectedUser = row.original;
                    console.log(selectedUser);

                    return selectedUser
                        ? `${selectedUser?.name || t('marketGrid.NoName')} - ${selectedUser?.id}`
                        : t('marketGrid.NoUser');
                }
            },
            { accessorKey: 'address', header: t('marketGrid.Address'), size: 130 },
            {
                accessorKey: 'city',
                header: t('marketGrid.City'),
                size: 130,
                Cell: ({ row, cell }: any) => {
                    let selectedID: number = row.original.city;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            },
            { accessorKey: 'dimensions', header: t('marketGrid.Dimensions'), size: 130 }
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: data || ([] as any),
        initialState: { showColumnFilters: true },
        enableEditing: true,

        getRowId: row => row.id,
        localization: i18n.language === 'hr' ? MRT_Localization_HR : (MRT_Localization_EN as any),
        state: {
            isLoading: isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching
        },
        renderRowActions: ({ row, table }) => {
            const onClick = () => {
                let selectedID: number = row.original.id;
                setSelectedRow(selectedID);
                setDetailsDialog(true);
            };

            return (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip title='Edit'>
                        <IconButton onClick={onClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        }
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <StorageAdminEditDialog selected={selectedRowID} open={openDetailsDialog} onClose={handleClose} />
        </>
    );
};
