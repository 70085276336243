import axios, { InternalAxiosRequestConfig } from 'axios';
import { STATUS_CODE_BAD_REQUEST, STATUS_CODE_UNAUTHORIZED } from 'parameters';

const apiUrl = process.env.REACT_APP_APP_BASE_URL;

const publicAxios = axios.create({
    baseURL: apiUrl
});

publicAxios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        return config;
    },
    error => {
        console.log('Axios interceptor error ');
        return Promise.reject(error);
    }
);

// Add a response interceptor
publicAxios.interceptors.response.use(
    function (response) {
        // console.log('Axios interceptor response ' + JSON.stringify(response));
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (error?.response?.status === STATUS_CODE_UNAUTHORIZED && !originalRequest._retry) {
            //
        } else if (error?.response?.status === STATUS_CODE_BAD_REQUEST) {
            //
        }

        return Promise.reject(error);
    }
);

export default publicAxios;
