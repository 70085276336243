export const taskUserState = ['SUBMITTER', 'ACCEPTOR'] as const;

export type TaskUserState = (typeof taskUserState)[number];

export const taskTypes = ['TRANSPORTATION', 'SHIPPING', 'STORAGE'] as const;

export type TaskTypes = (typeof taskTypes)[number];

export const taskFilters = [
    'ACCEPTED',
    'PUBLISHED',
    'COMPLETED',
    'CANCELED_SUBMITTER',
    'CANCELED_ACCEPTOR',
    'IN_PROGRESS'
] as const;

export type TaskFilters = (typeof taskFilters)[number];
