import { Outlet } from 'react-router-dom';
import { AuthorizationProvider } from 'context';

export const AuthorizedContextRoutes = () => {
    return (
        <AuthorizationProvider>
            <Outlet />
        </AuthorizationProvider>
    );
};
