import { createContext, useContext, useMemo, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { UserContextState } from './UserContext.types';
import { IUserProfile } from 'models';
import { LinearProgress } from '@mui/material';
import { GetUser } from 'services';
import { AuthorizationContext } from 'context';

const contextDefaultValues: UserContextState = {
    userProfileQuery: {} as UseQueryResult<IUserProfile, unknown>,
    setUserLoading: () => {
        throw new Error('User Loading not avaliable');
    },
    userId: Infinity,
    selectedUserId: Infinity,
    setSelectedUserId: () => {
        throw new Error('User Loading not avaliable');
    },
    forceUserDataInput: false
};

export const UserContext = createContext<UserContextState>(contextDefaultValues);

export const UserProvider = ({ children }: any) => {
    const [userLoading, setUserLoading] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number>(Infinity);

    const { getUserProfile } = useContext(AuthorizationContext);

    const userId = useMemo(() => {
        return getUserProfile?.id ?? null;
    }, [getUserProfile]);

    // set to 1 day, there is no need for this to be downloaded on every refresh
    const userProfileQuery = useQuery(['userData', userId], () => GetUser({ id: userId }), {
        staleTime: 86400,
        enabled: typeof userId === 'number'
    });

    const forceUserDataInput = useMemo(() => {
        if (userProfileQuery.data) {
            return !Object.values(userProfileQuery.data).every(value => value !== undefined && value !== null);
        } else {
            return false;
        }
    }, [userProfileQuery.data]);

    const loading = useMemo(() => userLoading || userProfileQuery.isLoading, [userLoading, userProfileQuery.isLoading]);

    return (
        <UserContext.Provider
            value={{
                userProfileQuery,
                setUserLoading,
                userId,
                forceUserDataInput,
                selectedUserId,
                setSelectedUserId
            }}>
            {loading && <LinearProgress />}
            {children}
        </UserContext.Provider>
    );
};
