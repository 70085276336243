import { ROUTES } from 'parameters';
import { useLocation } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlined from '@mui/icons-material/StoreOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

export const useNav = (route: 'app' | 'admin') => {
    const location = useLocation();

    const useNavigation = {
        app: [
            {
                route: ROUTES.home,
                icon: <HomeOutlinedIcon />,
                text: 'skeleton.Home',
                selected: false
            },
            {
                route: ROUTES.app,
                icon: (
                    <>
                        {location.pathname === ROUTES.app && <DashboardIcon />}
                        {location.pathname !== ROUTES.app && <DashboardOutlinedIcon />}
                    </>
                ),
                text: 'skeleton.Dashboard',
                selected: location.pathname === ROUTES.app
            },
            {
                route: ROUTES.market,
                icon: (
                    <>
                        {location.pathname.includes(ROUTES.market) && <StoreIcon />}
                        {!location.pathname.includes(ROUTES.market) && <StoreOutlined />}
                    </>
                ),
                text: 'skeleton.Market',
                selected: location.pathname.includes(ROUTES.market)
            },
            {
                route: ROUTES.vehicle,
                icon: (
                    <>
                        {location.pathname.includes(ROUTES.vehicle) && <LocalShippingIcon />}
                        {!location.pathname.includes(ROUTES.vehicle) && <LocalShippingOutlinedIcon />}
                    </>
                ),
                text: 'skeleton.Vehicle',
                selected: location.pathname.includes(ROUTES.vehicle)
            },
            {
                route: ROUTES.storage,
                icon: (
                    <>
                        {location.pathname.includes(ROUTES.storage) && <WarehouseIcon />}
                        {!location.pathname.includes(ROUTES.storage) && <WarehouseOutlinedIcon />}
                    </>
                ),
                text: 'skeleton.Storage',
                selected: location.pathname.includes(ROUTES.storage)
            },
            {
                route: ROUTES.mytasks,
                icon: (
                    <>
                        {location.pathname.includes(ROUTES.mytasks) && <AssignmentIcon />}
                        {!location.pathname.includes(ROUTES.mytasks) && <AssignmentOutlinedIcon />}
                    </>
                ),
                text: 'skeleton.Tasks',
                selected: location.pathname.includes(ROUTES.mytasks)
            }
        ],
        admin: [
            {
                route: ROUTES.users,
                icon: (
                    <>
                        {location.pathname.includes(ROUTES.users) && <PeopleIcon />}
                        {!location.pathname.includes(ROUTES.users) && <PeopleOutlinedIcon />}
                    </>
                ),
                text: 'skeleton.Users',
                selected: location.pathname.includes(ROUTES.users)
            },
            {
                route: ROUTES.vehicle,
                icon: (
                    <>
                        {location.pathname.includes(ROUTES.vehicle) && <LocalShippingIcon />}
                        {!location.pathname.includes(ROUTES.vehicle) && <LocalShippingOutlinedIcon />}
                    </>
                ),
                text: 'skeleton.Vehicles',
                selected: location.pathname.includes(ROUTES.vehicle)
            },
            {
                route: ROUTES.storage,
                icon: (
                    <>
                        {location.pathname.includes(ROUTES.storage) && <WarehouseIcon />}
                        {!location.pathname.includes(ROUTES.storage) && <WarehouseOutlinedIcon />}
                    </>
                ),
                text: 'skeleton.Storages',
                selected: location.pathname.includes(ROUTES.storage)
            }
        ]
    };
    return useNavigation[route];
};

export default useNav;
