import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import {
    Autocomplete,
    Checkbox,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import { GetCountries, GetLanguages, GetServices } from 'services';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { ICountry, ILanguage, IService, IUserProfile, UserFormValues } from 'models';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseQueryResult, useQuery } from 'react-query';
import { GlobalContext } from 'context';
import { removeNull } from 'utils';
import { FormSimpleStyled, SelectProfileStyled, TextFieldProfileStyled } from 'components';

interface UserProfileFormProps {
    userQuery: UseQueryResult<IUserProfile, unknown>;
    onSubmit: (data: UserFormValues) => void;
    updatePending: boolean;
}

export const UserProfileForm = forwardRef(({ userQuery, onSubmit, updatePending }: UserProfileFormProps, ref) => {
    const formRef = useRef<any>(null);

    const { t } = useTranslation();

    const { cityListQuery } = useContext(GlobalContext);

    const countryListQuery = useQuery(['countryList'], () => GetCountries(), { staleTime: 86400 });

    const languageListQuery = useQuery(['languageList'], () => GetLanguages(), { staleTime: 86400 });

    const servicesQuery = useQuery(['userServicesList'], () => GetServices(), { staleTime: 86400 });

    const loading = useMemo(
        () =>
            !cityListQuery.isLoading &&
            !countryListQuery.isLoading &&
            !languageListQuery.isLoading &&
            !userQuery.isLoading &&
            !servicesQuery.isLoading,
        [cityListQuery, countryListQuery, languageListQuery, userQuery, servicesQuery]
    );

    const validationSchema = Yup.object().shape({
        user: Yup.object().shape({
            address: Yup.string().required(t('Email is required')),
            city: Yup.number().required(t('City is required')),
            contact_number: Yup.string()
                .typeError(t('Contact can be only a number'))
                .required(t('Contact is required'))
                .matches(
                    /([0-9\s\\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
                    t('Use Number in format +385912178901')
                ),
            country: Yup.number().required(t('Country is required')),
            created_at: Yup.string().notRequired(),
            id: Yup.number().notRequired(),
            language: Yup.number(),
            name: Yup.string().required(t('Name is required')),
            oib: Yup.string()
                .required(t('VATID is required'))
                .test('len', t('Must be exactly 11 characters'), val => val?.length === 11),
            updated_at: Yup.string().notRequired(),
            user: Yup.string().notRequired()
        }),
        services: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().nullable(),
                    id: Yup.number().nullable(),
                    active: Yup.boolean().nullable()
                })
            )
            .notRequired()
    });

    const {
        control,
        setValue,
        getValues,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<UserFormValues>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema as any)
    });

    const { fields, append, update } = useFieldArray<UserFormValues>({
        control,
        name: 'services'
    });

    const watchsServicesArray = watch('services');

    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchsServicesArray[index]
        };
    });

    useEffect(() => {
        if (!!userQuery.data?.id) {
            const cleanedUser = removeNull(userQuery.data as any);
            Object.keys(cleanedUser).forEach((key: string) => {
                const formKey = `user.${key}` as any;
                setValue(formKey, cleanedUser[key], { shouldDirty: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userQuery.data]);

    useEffect(() => {
        const values = getValues();
        if (!!userQuery.data?.id) {
            if (!!servicesQuery.data?.length) {
                servicesQuery.data.forEach((el: IService) => {
                    let exist = false;
                    for (let i = 0; i < values.services.length; i++) {
                        if (values.services[i].id === el.id) {
                            const found = userQuery.data.service.find(serviceId => serviceId === values.services[i].id);
                            el.active = !!found;
                            exist = true;
                            update(i, el);
                        }
                    }
                    if (!exist) {
                        const found = userQuery.data.service.find(serviceId => serviceId === el.id);
                        el.active = !!found;
                        append(el);
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [servicesQuery.data, userQuery.data]);

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            handleSubmit(onSubmit)();
        }
    }));

    return (
        <>
            {loading ? (
                <FormSimpleStyled ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction='row' gap={4}>
                        <Grid
                            container
                            item={true}
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='start'
                            gap={2}
                            xs={6}
                            p={1}>
                            <Typography variant='h6' gutterBottom>
                                {t('userProfile.GeneralInformation')}
                            </Typography>
                            <Controller
                                name='user.name'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldProfileStyled
                                        {...field}
                                        required
                                        id='user.name'
                                        label={`${t('userProfile.Name')}`}
                                        error={errors?.user?.name ? true : false}
                                        helperText={errors?.user?.name?.message ? errors?.user?.name?.message : null}
                                        InputProps={{
                                            style: {
                                                borderRadius: '20px'
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name='user.oib'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldProfileStyled
                                        {...field}
                                        required
                                        id='user.oib'
                                        label={`${t('userProfile.VATID')}`}
                                        error={errors?.user?.oib ? true : false}
                                        helperText={errors?.user?.oib?.message ? errors?.user?.oib?.message : null}
                                        InputProps={{
                                            style: {
                                                borderRadius: '20px'
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name='user.address'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldProfileStyled
                                        {...field}
                                        required
                                        id='user.address'
                                        label={`${t('userProfile.Address')}`}
                                        error={errors?.user?.address ? true : false}
                                        helperText={
                                            errors?.user?.address?.message ? errors?.user?.address?.message : null
                                        }
                                        InputProps={{
                                            style: {
                                                borderRadius: '20px'
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name='user.city'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            onChange={(event: any, newValue: any | null) => {
                                                onChange(newValue.value);
                                            }}
                                            options={
                                                cityListQuery.data?.map(el => {
                                                    return { label: el.name, value: el.id };
                                                }) || []
                                            }
                                            value={
                                                value
                                                    ? cityListQuery.data
                                                          ?.filter(el => el.id === value)
                                                          .map(el => {
                                                              return { label: el.name, value: el.id };
                                                          })[0]
                                                    : null
                                            }
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    label={t('taskDetails.city') + '*'}
                                                    variant='outlined'
                                                    sx={{
                                                        '& fieldset': {
                                                            borderRadius: '20px'
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText error={true}>
                                            {errors.user?.message ? errors.user?.message : ''}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name='user.country'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <FormControl fullWidth>
                                            <InputLabel id={'country'}>{t('userProfile.Country') + '*'}</InputLabel>
                                            <SelectProfileStyled
                                                onChange={onChange}
                                                value={value || ''}
                                                variant='outlined'
                                                defaultValue={''}
                                                label={t('userProfile.Country') + '*'}
                                                error={errors?.user?.country ? true : false}>
                                                {countryListQuery.data !== undefined ? (
                                                    countryListQuery.data?.map((country: ICountry) => (
                                                        <MenuItem key={country.id} value={country.id}>
                                                            {country.name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value={0}>{t('dropdown.NoData')}</MenuItem>
                                                )}
                                            </SelectProfileStyled>
                                            <FormHelperText error={true}>
                                                {errors?.user?.country?.message ? errors?.user?.country?.message : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </>
                                )}
                            />

                            <Controller
                                name='user.language'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id={'language'}>{t('userProfile.LanguageLabel') + '*'}</InputLabel>
                                        <SelectProfileStyled
                                            onChange={onChange}
                                            value={value || ''}
                                            variant='outlined'
                                            defaultValue={''}
                                            label={t('userProfile.LanguageLabel') + '*'}
                                            error={errors?.user?.language ? true : false}>
                                            {languageListQuery.data !== undefined ? (
                                                languageListQuery.data?.map((language: ILanguage) => (
                                                    <MenuItem key={language.id} value={language.id}>
                                                        {language.name}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem value={0}>{t('dropdown.NoData')}</MenuItem>
                                            )}
                                        </SelectProfileStyled>
                                        <FormHelperText error={true}>
                                            {errors?.user?.language?.message ? errors?.user?.language?.message : ''}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name='user.contact_number'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldProfileStyled
                                        {...field}
                                        required
                                        id='user.contact_number'
                                        label={t('userProfile.Contact')}
                                        placeholder='Use Number in format +385912178901'
                                        error={errors?.user?.contact_number ? true : false}
                                        helperText={
                                            errors?.user?.contact_number?.message
                                                ? errors?.user?.contact_number?.message
                                                : null
                                        }
                                        InputProps={{
                                            style: {
                                                borderRadius: '20px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            container
                            item={true}
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='start'
                            gap={1}
                            xs={4}
                            p={1}>
                            <Typography variant='h6' gutterBottom>
                                {t('userProfile.Services')}
                            </Typography>
                            <List>
                                {controlledFields.map((service, index) => {
                                    const labelId = `checkbox-list-label-${service.id}`;
                                    return (
                                        <Controller
                                            name={`services[${index}].active` as any}
                                            control={control}
                                            key={`services[${index}].active`}
                                            render={({ field: { onChange, value } }) => (
                                                <ListItemButton key={service.id} role={undefined} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            checked={!!value}
                                                            id={`services[${index}].id`}
                                                            onChange={e => onChange(e.target.checked)}
                                                            edge='start'
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            style={{ color: '#08A66C' }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={t('profile' + service.name)} />
                                                </ListItemButton>
                                            )}
                                        />
                                    );
                                })}
                            </List>
                        </Grid>
                    </Grid>
                </FormSimpleStyled>
            ) : (
                <CircularProgress color='inherit' />
            )}
        </>
    );
});
