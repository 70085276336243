import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, Tooltip, useTheme } from '@mui/material';

type Props = {
    title: string;
    description: string;
    icon: React.ReactNode;
};

export function HomeServiceCard(props: Props) {
    const { title, description, icon } = props;
    const theme = useTheme();

    return (
        <Tooltip title={description}>
            <>
                {icon}
                <Card
                    sx={{
                        maxWidth: 345,
                        minWidth: 300,
                        borderRadius: '20px',
                        opacity: '0.73',
                        background: ' rgba(255, 255, 255, 0.20)',
                        backdropFilter: 'blur(81.5px)'
                    }}>
                    <CardContent>
                        <Grid container direction='column' alignItems='flex-start'>
                            <Typography
                                gutterBottom
                                variant='h6'
                                component='h5'
                                sx={{ color: theme.palette.primary.contrastText }}>
                                {title}
                            </Typography>
                            <Typography
                                gutterBottom
                                variant='body2'
                                component='p'
                                sx={{ color: theme.palette.primary.contrastText }}>
                                {description}
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>
            </>
        </Tooltip>
    );
}
