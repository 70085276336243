import { ErrorWrapper } from 'components/ux/error-wrapper/ErrorWrapper';
import { t } from 'i18next';
import { ROUTES } from 'parameters';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {};

export function SessionCheck(props: Props) {
    const navigate = useNavigate();

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate(ROUTES.home);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [navigate]);
    const title = t('sessionCheck.Title');

    return (
        <ErrorWrapper>
            <h1 id='hero_title'>{title}</h1>
        </ErrorWrapper>
    );
}
