import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthorizationContext } from 'context';
import { SessionCheck } from 'components';

export const AuthentificatedRoutes = () => {
    const { isAuth } = useContext(AuthorizationContext);

    return isAuth ? <Outlet /> : <SessionCheck></SessionCheck>;
};
