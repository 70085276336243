import { GridStyledWrapper } from './ErrorWrapper.styled';

interface Props {
    children: React.ReactNode;
}

export const ErrorWrapper = ({ children }: Props) => {
    return (
        <GridStyledWrapper container={true} direction='row' justifyContent='center' alignItems='center'>
            {children}
        </GridStyledWrapper>
    );
};
