import { ComponentType } from 'react';
import Link from '@mui/material/Link';
import { ButtonStyleProps, StyledButton, WrapperIcon, ButtonLink } from './Buttons.styled';
import { ButtonProps } from '@mui/material';

interface Props {
    text: string | React.ReactElement<any, any>;
    href?: string;
    size?: 'small' | 'medium' | 'large';
    external?: boolean;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    icon?: any; // need to use any to avoid potential conflicts with svgr-plugin
    iconfirst?: boolean;
    color?: 'success' | 'error' | 'inherit' | 'primary' | 'secondary' | 'info' | 'warning';
    variant?: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
    fullWidth?: boolean;
    disablemargin?: boolean;
    disablepadding?: boolean;
    className?: string;
}

type ComponentProps = ButtonStyleProps & ButtonProps;

const handleButtonWrapping = (Component: ComponentType<ComponentProps>, props: Props) => {
    const {
        href,
        size = 'medium',
        text,
        external,
        onClick,
        type,
        color,
        icon,
        disabled,
        fullWidth = false,
        disablemargin,
        iconfirst,
        variant,
        disablepadding
    } = props;

    const button = (
        <Component
            color={color}
            fullWidth={fullWidth}
            type={type}
            size={size}
            disabled={disabled}
            iconfirst={iconfirst}
            disablepadding={disablepadding}
            disablemargin={disablemargin}
            variant={variant}
            onClick={() => {
                onClick && onClick();
            }}>
            {text}
            {icon ? <WrapperIcon>{icon}</WrapperIcon> : null}
        </Component>
    );

    if (href) {
        return (
            <Link href={href}>
                <ButtonLink target={external ? '_blank' : undefined}>{button}</ButtonLink>
            </Link>
        );
    }
    return button;
};

export const BytegaButton = (props: Props) => handleButtonWrapping(StyledButton, props);
